import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import VueCookies from 'vue-cookies'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as uiv from 'uiv'

import routerConfig from '@/router';
import Store from '@/store/Store';
import routes from './routes'
import EventRepository from './API/EventRepository'

import { StoreMutations as GlobalMutations } from "@/store/global/mutations";
import { StoreState } from './store/global/state'
  
// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: routerConfig
})

router.beforeEach((to, _, next) => {
  EventRepository.viewPage(to.fullPath);
  next();
});

const app = createApp(App)

app.use(uiv);
app.use(router)
app.use(Store);
app.use(VueCookies, { expire: '30d' });

app.config.globalProperties.$getSession = () => {
  return app.config.globalProperties.$cookies.get('loc_session');
}

app.config.globalProperties.$setSession = (session) => {
  app.config.globalProperties.$cookies.set('loc_session', session); 
}

app.config.globalProperties.$hasSession = () => {
  let session = app.config.globalProperties.$getSession();
  return session && session != "null";
}

app.config.globalProperties.$getAccessCode = () => {
  return app.config.globalProperties.$cookies.get('loc_access_code');
}

app.config.globalProperties.$setAccessCode = (session) => {
  app.config.globalProperties.$cookies.set('loc_access_code', session); 
}

app.config.globalProperties.$getSlug = () => {
  return app.config.globalProperties.$cookies.get('loc_homepage_slug');
}

app.config.globalProperties.$setSlug = (session) => {
  app.config.globalProperties.$cookies.set('loc_homepage_slug', session); 
}

app.config.globalProperties.$hasSlug = () => {
  let slug = app.config.globalProperties.$getSlug();
  return slug && slug != "null" && slug != "undefined";
}

app.config.globalProperties.$logout = (destination) => {
  app.config.globalProperties.$setSession(null);
  app.config.globalProperties.$setSlug(null);
  app.config.globalProperties.$setAccessCode(null);
  router.push({name: routes.login.name, query: {destination: destination}});
}

app.config.globalProperties.$showPendingEmailModal = () => {
  Store.commit(GlobalMutations.SET_SHOW_VERIFY_EMAIL, true);
}

app.config.globalProperties.$hidePendingEmailModal = () => {
  Store.commit(GlobalMutations.SET_SHOW_VERIFY_EMAIL, false);
}

app.config.globalProperties.$isPendingEmailModalShowing = () => {
  return Store.state[StoreState.showVerifyEmail];
}

app.mount('#app')

export default app;