<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-8 col-sm-offset-2 mt20" v-if="home">

          <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 small-show">
            <img class="home-image" :src="home.image" />
          </div>
            <div 
              class="col-xs-12 col-sm-12 col-md-12 " 
              :class="{'col-lg-4': home.image, 'col-lg-offset-2': home.image, 'col-lg-12': !home.image}"
            >
              <h1>{{home.title}}</h1>
              <h2>{{home.title2}}</h2>
              <h3>{{home.title3}}</h3>
              <p class="desc">{{home.desc}}</p>

              <button v-if="home.buttonTitle" class="btn btn-primary btn-main">{{home.buttonTitle}}</button>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 small-hidden">
              <img height="400" :src="home.image" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12" style="margin-top:80px;"></div>
            <div :class="sectionColumn(index)" v-for="(section, index) in home.sections" :key="section.id">
              <img height="200" :src="section.image" />
              <h3>{{section.title}}</h3>
              <br>
              <p class="desc-section">{{section.desc}}</p>
              <br>
              <a :href="buttonLink(section)" v-if="section.buttonLink" :target="linkTarget(section.buttonLink)">
                <button class="btn btn-default btn-main">{{section.buttonTitle}}</button>
              </a>
              <br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreActions as GlobalActions } from '@/store/global/actions';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/home/state";
import { StoreActions as actions } from "@/store/home/actions";
import MainNav from '@/components/MainNav.vue'

export default {
  name: "14Can",
  components: {
    SyncLoader,
    MainNav
  },
  mounted() {

    this.accessCode = this.$getAccessCode();

    this.$store.dispatch(GlobalActions.getSessionDetails, (session) => {
      this.userProfile = !session.studentProfile;
    });

    this.loading = true;
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      this.loading = false;
      if(user) {

        this.accessCode = user.accessCode;
      }
    });

    if(this.$hasSlug()) {
      let slug = this.$getSlug();
      this.dispatch(actions.homeBySlug, slug);
    } else {
      if(!this.$hasSession()) {
        this.$logout();
        return;
      }
      this.dispatch(actions.home);
    }

  },
  computed: {
    ...mapState(StorePaths.home, [state.loading, state.error, state.home]),
    profileRoute() {
      if(this.userProfile) {
        return {
          name: routes.myProfile.name
        } 
      } else {
        return {
          name: routes.myStudentProfile.name
        } 
      }
    },
    canRoute() {
      return {
        name: routes.words.name
      }
    }
  },
  data() {
    return {
      loading: false,
      userProfile: false,
      accessCode: null
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.home}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.home}/${key}`, val);
    },
    sectionColumn(index) {
      let total = this.home.sections.length;
      let isLast = total == index + 1;
      var classes = {
        'col-xs-12': true,
        'col-sm-12': true,
        'col-md-12': true,
        'col-lg-4': true,
        'col-lg-offset-4': total == 4 && isLast,
        'col-lg-offset-2': total == 5 && index == 3,
        'mt20': true
      }

      return classes;
    },
    linkTarget(link) {
      let app = !link.includes("https://")
      if(app) {
        return ""
      }

      return "_blank"
    },
    buttonLink(section) {
      if(section.buttonTitle == 'Visit Store') {
        return `${section.buttonLink}?a=${this.accessCode}`;
      }

      return section.buttonLink;
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}

.home-image {
  height: 300px;
}

@media(max-width: 767px) {
  .home-image {
    height: 150px;
  }
}
</style>