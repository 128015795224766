<template>
  <Modal v-model="show" :title="`${this.id ? 'Edit' : 'Add'} Access Code`">
    <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px; margin-bottom: 20px"
    ></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="form-group" v-if="!loading">
      <label>Access Code
        <button @click="random" class="btn btn-default">
          <i class="fa fa-refresh" />
        </button>
      </label>
      <input
        class="form-control"
        placeholder=""
        v-model="accessCode"
        @keyup.enter="saveClick"
      />
    </div>
      <div class="form-group" v-if="!loading">
      <label>Total Allowed Uses</label>
      <input
        class="form-control"
        placeholder=""
        v-model="allowedUses"
        type="number"
        @keyup.enter="saveClick"
      />
    </div>

    <!-- <div class="form-group">
      <label>Type</label>
      <select class="form-control" v-model="type">
        <option value="null">Select a Type</option>
        <option value="Classroom">Classroom</option>
        <option value="Doctor">Doctor</option>
        <option value="Homepage">Homepage</option>
      </select>
    </div> -->

        <div class="form-group" v-if="type == 'Homepage'">

      <div v-if="!loading">
      <label>Homepage</label>
      <select class="form-control" v-model="homepageId">
        <option :value="null">None</option>
        <option
        v-for="homepage in homepages"
        :key="homepage.id"
        :value="homepage.id">
          {{homepage.slug}} ({{homepage.title}})
        </option>
      </select>
      </div>
    </div>

    <div class="form-group" v-if="type == 'Doctor'">

      <div v-if="!loading">
      <label>Doctor</label>
      <select class="form-control" v-model="doctorId">
        <option :value="null">None</option>
        <option
        v-for="doctor in doctors"
        :key="doctor.id"
        :value="doctor.id">
          {{doctor.name}}
        </option>
      </select>
      </div>
    </div>

    <div class="form-group" v-if="type == 'Classroom'">

      <div v-if="!loading">
        <label>School</label>
        <select class="form-control" v-model="schoolId">
          <option :value="null">None</option>
          <option
          v-for="school in schools"
          :key="school.id"
          :value="school.id">
            {{school.name}}
          </option>
        </select>
        <br>
        <label>Classroom</label>
        <select class="form-control" v-model="classroomId">
          <option :value="null">None</option>
          <option
          v-for="classroom in classrooms"
          :key="classroom.id"
          :value="classroom.id">
            {{classroom.name}}
          </option>
        </select>
      </div>

      <div class="mt20" v-if="classroomId || schoolId">
        <button @click="removeClassroom" class="btn btn-default">Remove Classroom</button>
      </div>
    </div>
    <template #footer>
      <div>
        <Btn @click="show = false">Cancel</Btn>
        <Btn type="primary" @click="saveClick" :disabled="loading">Save</Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/accessCodes/state";
import { StoreActions as actions } from "@/store/admin/accessCodes/actions";
import { StoreMutations as mutations } from "@/store/admin/accessCodes/mutations";
import Utils from '@/utils/Utils';

export default {
  name: "AdminAccessCodeFormModal",
  mounted() {
    this.show = this.open;
  },
  components: {
    SyncLoader,
  },
  computed: {
    ...mapState(StorePaths.adminAccessCodes, [
      state.loading, state.doctors, state.error, state.schools, state.homepages
    ]),
    classrooms() {
      if(!this.schoolId) { return [] }
      let school = this.schools.find(it => {
        return it.id == this.schoolId
      })
      return school.classrooms ?? []
    }
  },
  props: {
    open: {
      default: false,
    },
    id: {
      default: null,
    },
  },
  data() {
    return {
      show: false,
      accessCode: "",
      allowedUses: 0,
      doctorId: null,
      schoolId: null,
      classroomId: null,
      type: 'Homepage',
      homepageId: null
    };
  },
  methods: {
    saveClick() {
      let data = {
        accessCode: this.accessCode,
        allowedUses: this.allowedUses,
        doctorId: this.doctorId,
        classroomId: this.classroomId,
        homepageId: this.homepageId
      };
      this.loading = true;

      let didCreate = () => {
        this.show = false;
      };

      if (this.id) {
        this.dispatch(actions.updateAccessCode, {id: this.id, data: data, callback: didCreate});
      } else {
        this.dispatch(actions.createAccessCode, {data: data, callback: didCreate});
      }
    },
    getAccessCode() {

      let didGetCode = (code) => {
        this.accessCode = code.accessCode;
        this.allowedUses = code.allowedUses;
        this.doctorId = code.doctorId;
        this.classroomId = code.classroomId;
        this.homepageId = code.homepageId;
        if(code.school) {
          this.schoolId = code.school.id;
        }

        if(this.doctorId) {
          this.type = "Doctor";
        } else if (this.classroomId) {
          this.type = "Classroom";
        } else if(this.homepageId) {
          this.type = "Homepage";
        } else {
          this.type = null;
        }
      };

      this.dispatch(actions.getAccessCode, {id: this.id, callback: didGetCode});
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminAccessCodes}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminAccessCodes}/${key}`, val);
    },
    removeClassroom() {
      this.classroomId = null;
      this.schoolId = null;
    },
    reset() {
        this.commit(mutations.SET_ERROR, null);
        this.accessCode = null;
        this.allowedUses = null;
        this.doctorId = null;
        this.classroomId = null;
        this.schoolId = null;
        this.homepageId = null;
    },
    random() {
      this.accessCode = Utils.randomString(6);
    }
  },
  watch: {
    show(newVal) {
      this.$emit("changed", newVal);
    },
    open(newVal) {
      this.show = newVal;
      if (newVal) {
        this.accessCode = "";
        this.allowedUses = "";
        this.commit(mutations.SET_ERROR, null);

        this.dispatch(actions.getDoctors);
        this.dispatch(actions.getSchools);
        this.dispatch(actions.getHomepages);

        if (this.id) {
          this.getAccessCode();
        }
      } else {
        this.reset();
      }
    },
    type() {
      this.doctorId = null;
      this.schoolId = null;
      this.classroomId = null;
      this.homepageId = null;
    }
  },
};
</script>

<style>
.modal-content {
  border-radius: 15px;
}
</style>

<style scoped>
.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>