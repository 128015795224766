<template>
  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2 col" @click="wordClicked">
      <div class="row">
        <div class="col-sm-11 curve" :class="wordStyle" :style="{background: word.color}">
          <div class="word">
            <img :src="wordImg"/>
            <p v-if="error" class="error-msg">{{error}}</p>
            <span class="star" v-if="word.completed"> 
              <i class="fa fa-star"/> 
            </span>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
    name: "WordTile",
    data() {
      return {
        error: null
      }
    },
    mounted() {
    },
    props: {
      word: {
        required: true
      }
    },
    computed: {
      wordImg() {
        return this.word.image
      },
      wordStyle() {
        let values = {
          disabled:  !this.error && !this.word.enabled,
        }
        return values;
      }
    },
    methods: {
      wordClicked() {
        
        if(this.word.completed) {
          this.showError("You already completed this word!");
          return
        }

        if(!this.word.enabled) {
          this.showError("This word has not been unlocked yet!");
        }
      },
      showError(msg) {
        this.error = msg;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    }
}
</script>

<style scoped>
.word {
  height: 150px;
  position: relative;
}

.curve {
  border-radius: 20px;
}

img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.col {
  margin-bottom: 20px;
}

.disabled {
  opacity: 0.5;
}

.error-msg {
  background: white;
  border-radius: 8px;
  color:black;    
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.star {
  background: white;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 30px;
  margin-top: 10px;
}

.fa-star {
  font-size: 10pt;
  color: black;
}
</style>