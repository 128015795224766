
import moment from 'moment';

export default class CompletedWord {
    constructor(adapter = {
        id: 0,
        wordId: 0,
        profileId: null,
        userId: null,
        wordName: '',
        userName: '',
        profileName: '',
        ts: ""
    }) {
        this.id = adapter.id;
        this.wordId = adapter.wordId;
        this.profileId = adapter.profileId;
        this.userId = adapter.userId;
        this.userName = adapter.userName;
        this.profileName = adapter.profileName;
        this.wordName = adapter.wordName;
        
        var utc = moment.utc(adapter.ts).toDate();
        this.ts = moment(utc).local().format("MMM DD YYYY hh:mm a");
    }

    name() {
        if(this.userName) {
            return this.userName
        } else if (this.profileName) {
            return this.profileName
        }

        return '';
    }
}