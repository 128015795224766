import APIConnection from '@/API/APIConnection';
export default {
    getAll(){
        return APIConnection.get('admin/words');
    },
    get(id) {
        return APIConnection.get(`admin/words/${id}`);
    },
    update(id, data) {
        return APIConnection.put(`admin/words/${id}`, data);
    }
}