<template>
  <div>
    <main-nav :color="'black'" />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Admin</h2>
        </div>
      </div>
      <div class="row mt20">
        <admin-nav :active="activeRoute"/>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
          <div class="secondary ml30 curved mr20">
            <h3>Access Codes</h3>

            <sync-loader
              :loading="loading"
              :color="`#f35b2c`"
              :size="'15px'"
              style="margin-top: 20px"
            ></sync-loader>

            <div class="row mt20" v-if="error">
              <div class="col-sm-12">
                <div class="alert" role="alert">
                  {{ error }}
                </div>
              </div>
            </div>

            <button class="btn btn-default curved" @click="createClick">
              <i class="fa fa-plus" /> Create
            </button>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>AccessCode</th>
                  <th>Total Allowed Uses</th>
                  <th>Total Times Used</th>
                  <th>Remaining Uses</th>
                  <th>Homepage</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="accessCode in accessCodes" :key="accessCode.id" class="pointer">
                  <td @click="edit(accessCode)">{{accessCode.accessCode}}</td>
                  <td>{{accessCode.allowedUses}}</td>
                  <td>{{accessCode.numUses}}</td>
                  <td>{{accessCode.remainingUses}}</td>
                  <td>{{ accessCode.homepage?.slug || '' }}</td>
                  <td>
                    <button class="btn btn-default" @click="edit(accessCode)">
                      <i class="fa fa-pencil" />
                    </button>
                    <button class="btn btn-danger ml10" @click="pendingDeleteCode = accessCode.accessCode">
                      <i class="fa fa-trash" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <admin-access-code-form-modal
        :open="showAccessCodeModal"
        :id="editAccessCodeId"
        @changed="editFinished"
      />

      <delete-confirmation
        :showing="showingDeleteConf"
        :name="pendingDeleteCode"
        @closed="closedDelete"
        @delete="deleteAccessCode"
      />

    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/accessCodes/state";
import { StoreActions as actions } from "@/store/admin/accessCodes/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";
import AdminAccessCodeFormModal from './AdminAccessCodeFormModal.vue';
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";

export default {
  name: "AdminAccessCodes",
  components: {
    MainNav,
    SyncLoader,
    AdminNav,
    AdminAccessCodeFormModal,
    DeleteConfirmation
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      }
    });

    this.dispatch(actions.getAccessCodes);
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      adminLinks: routes.adminLinks(),
      editAccessCodeId: null,
      showAccessCodeModal: false,
      pendingDeleteCode: null
    };
  },
  computed: {
    ...mapState(StorePaths.adminAccessCodes, [state.loading, state.accessCodes, state.error]),
    activeRoute() {
      return routes.admin.accessCodes.name;
    },
    showingDeleteConf() {
      return this.pendingDeleteCode != null
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminAccessCodes}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminAccessCodes}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    edit(accessCode) {
      this.editAccessCodeId = accessCode.id;
      this.showAccessCodeModal = true;
    },
    editFinished(val) {
      if(!val) {
        this.editAccessCodeId = null;
        this.showAccessCodeModal = false;
        this.dispatch(actions.getAccessCodes);
      }
    },
    createClick() {
      this.showAccessCodeModal = true
    },
    doctorName(accessCode) {
      if(accessCode.doctor) {
        return accessCode.doctor.name;
      }

      return "";
    },
    classroomName(accessCode) {
      if(accessCode.classroom) {
        var str = accessCode.classroom.name;
        if(accessCode.school) {
          str += ` (${accessCode.school.name})`
        }
        return str;
      }

      return "";
    },
    closedDelete() {
      this.pendingDeleteCode = null
    },
    deleteAccessCode() {
      let codeName = this.pendingDeleteCode;
      let code = this.accessCodes.find(it => {
        return it.accessCode == codeName;
      })

      if(code) {
        this.dispatch(actions.delete, code.id);
      }

      this.pendingDeleteCode = null;
    }
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

nav {
  margin-bottom: 0px;
}

td {
  text-align: left;
}

.curved {
  border-radius: 10px;
}

.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>