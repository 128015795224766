<template>
  <div v-if="schools.length > 0">
    <div class="row mt20" v-if="!selectedSchool">
      <div class="col-xs-12 col-sm-12">
        <h4> District</h4>
        <input class="form-control" placeholder="Filter" v-model="filter" />
        <hr />

        <div
          v-for="section in filteredSchools"
          :key="section.state"
          style="text-align: left"
        >
          <h5 class="mt20" style="color: gray">{{ section.state }}</h5>
          <div
            class="secondary school-container"
            v-for="school in section.schools"
            :key="school.name"
            @click="clickedSchool(school)"
          >
            <p class="ml10 pointer school">
              {{ school.name }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row mt20"
      v-if="
        selectedSchool &&
        selectedSchool.classrooms.length > 0 &&
        !selectedClassroom
      "
    >
      <div class="col-xs-12 col-sm-12">
        <h4>Select Your Classroom</h4>
        <input class="form-control" placeholder="Filter" v-model="filter" />
        <hr />

        <div style="text-align: left">
          <div
            class="secondary school-container"
            v-for="classroom in filteredClassrooms"
            :key="classroom.name"
            @click="clickedClassroom(classroom)"
          >
            <p class="ml10 pointer school">
              {{ classroom.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SchoolSection from "@/adapters/SchoolSection";

export default {
  name: "SelectSchoolClassroom",
  props: {
    schools: {
      required: true,
    },
  },
  data() {
    return {
      filter: "",
      selectedSchool: null,
      selectedClassroom: null,
    };
  },
  computed: {
    filteredSchools() {
      let sections = this.schools
        .filter((it) => {
          return (
            it.name.toLowerCase().includes(this.filter) ||
            it.state.toLowerCase().includes(this.filter)
          );
        })
        .reduce(function (r, a) {
          r[a.state] = r[a.state] || [];
          r[a.state].push(a);
          return r;
        }, Object.create(null));

      let keys = Object.keys(sections).sort();
      let schools = keys.map((key) => {
        let schools = sections[key];
        return new SchoolSection({ state: key, schools: schools });
      });

      return schools;
    },
    filteredClassrooms() {
      let classrooms = this.selectedSchool.classrooms.filter((it) => {
        return it.name.toLowerCase().includes(this.filter);
      });
      return classrooms;
    },
  },
  methods: {
    clickedSchool(school) {
        this.selectedSchool = school;
    },
    clickedClassroom(classroom) {
        this.selectedClassroom = classroom;
    }
  },
  watch: {
    selectedSchool(newVal) {
      this.$emit("selectedSchool", newVal);
    },
    selectedClassroom(newVal) {
      this.$emit("selectedClassroom", newVal);
    },
  },
};
</script>

<style>
.school {
  font-size: medium;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: 500;
}

.school-container {
  border-radius: 10px;
  padding-left: 5px;
  padding-top: 3px;
  background: white;
}
</style>