import UserRepository from "@/API/UserRepository";
import { StoreMutations } from "./mutations";
import User from "@/adapters/User";
import SessionDetails from "@/adapters/SessionDetails";
import Profile from "@/adapters/Profile";
import ProfileRepository from "@/API/ProfileRepository";

export const StoreActions = {
    getUser: 'getUser',
    getProfile: 'getProfile',
    getSessionDetails: 'getSessionDetails'
};

export const actions = {
    [StoreActions.getUser] ({ commit }, callback) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.get().then( res => {
            let user = res.user;
            callback(new User(user));
        }).catch(() => {
            callback(null);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getProfile] ({ commit }, callback) {
        commit(StoreMutations.SET_LOADING, true);

        ProfileRepository.current().then( res => {
            let profile = res.profile;
            callback(new Profile(profile));
        }).catch(() => {
            callback(null);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getSessionDetails] ({commit}, callback) {
        commit(StoreMutations.SET_LOADING, false);
        UserRepository.getSessionDetails()
        .then(res => {
            callback(new SessionDetails(res.details));
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_LOADING, false);
    }
}