export default class Word {
    constructor(adapter = {
        id: 0,
        name: "",
        image: "",
        color: "",
        link: "",
        enabled: true,
        completed: false,
        correctAnswers: []
    }) {
        this.id = adapter.id;
        this.name = adapter.name;
        this.image = adapter.image;
        this.color = adapter.color;
        this.link = adapter.link;
        this.enabled = adapter.enabled;
        this.completed = adapter.completed;
        this.correctAnswers = adapter.correctAnswers;
    }
}