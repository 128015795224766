export default class Doctor {
    constructor(adapter = {
        id: 0,
        name: "",
        state: ""
    }) {
        this.state = adapter.state;
        this.name = adapter.name;
        this.id = adapter.id;
        this.classrooms = adapter.classrooms;
    }
}