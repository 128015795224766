<template>
  <div
    class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3"
    v-if="question.type == 'options' || question.type == 'multi-options'"
  >
    <div class="row">
      <div
        v-for="(option, idx) in question.options"
        :key="idx"
        class="col-sm-6"
      >
        <div
          class="secondary option"
          :style="selectedStyle(option.option)"
          @click="optionClick(option.option)"
        >
          <img
            :src="option.image"
            v-if="option.image"
            style="height: 200px; border-radius: 20px; margin-top: 20px"
          />
          <p>
            {{ option.option }}
            <i class="fa fa-lg ml5" v-if="option.icon" :class="option.icon" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CanFormOption",
  props: {
    question: {
      required: true,
    },
    answer: {
        required: true
    },
    answers: {
        required: true
    },
    word: {
      required: true
    },
    wrongAnswer: {
      default: false
    }
  },
  methods: {
      selectedStyle(option) {
      var color = "";
      var textColor = "black";
      if(this.question.type == 'options') {
        if(this.answer == option) {
          color = this.word.color;
          if(this.wrongAnswer) {
            color = "#D9594C";
            textColor = "white";
            if(this.word.name == "Resilience") {
              color = "#000000";
            }
          }
        }
      } else if(this.question.type == 'multi-options') {
        if(this.answers.includes(option)) {
          color = this.word.color;
        }
      }

      return {
        background: color,
        color: textColor
      };
    },
    optionClick(option) {
        this.$emit("optionClick", option);
    }
  },
};
</script>

<style scoped>


.option {
  min-height: 50px;
  border-radius: 15px;
  margin: 10px;
  cursor: pointer;
}

.option > p {
  padding: 15px;
  text-overflow: ellipsis;
  overflow: auto;
}

p {
  font-size: 1.5em;
}
</style>