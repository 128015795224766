<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Canboards</h2>
        </div>
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-12 mt20">
          <div class="row" style="margin-left:20px;margin-right:20px;">
            <span v-for="word in words" :key="word.word">
              <router-link
              :class="linkStyle(word)"
              :to="canboard(word)">
                <WordTile :word="word" />
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WordTile from "@/components/can/WordTile.vue";
import StorePaths from '@/store/StorePaths';
import { mapState } from 'vuex';
import { StoreState as state } from '@/store/canboard/state';
import { StoreActions as actions } from '@/store/canboard/actions'; 
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';

export default {
  name: "14Can",
  components: {
    WordTile,
    SyncLoader,
    MainNav
  },
  mounted() {
    if(!this.$hasSession()) {
      this.$router.push({name: routes.register.name, query: {destination: 'can'}})
      return;
    }
    this.dispatch(actions.getCanboardWords);
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  computed: {
    ...mapState(StorePaths.canboard, [
      state.loading,
      state.words
    ]),
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    profileRoute() {
      return {
        name: routes.myProfile.name
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    linkStyle(word) {
      return {
        'disabled': !word.enabled
      }
    },
    canboard(word) {
      return {
        name: routes.canboard.name,
        params: {
          wordId: word.id
        }
      }
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.canboard}/${key}`,val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.canboard}/${key}`,val);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.get-started {
  font-size: x-large;
}
</style>