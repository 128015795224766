export default class AdminWord {
    constructor(adapter = {
        id: 0,
        name: "",
        image: "",
        color: "",
        link: "",
        sort: 0
    }) {
        this.id = adapter.id;
        this.name = adapter.name;
        this.image = adapter.image;
        this.color = adapter.color;
        this.link = adapter.link;
        this.sort = adapter.sort;
    }
}