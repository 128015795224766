export const StoreState = {
    loading: 'loading',
    error: 'error',
    books: 'books',
    bookId: 'bookId',
    book: 'book'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.books]: [],
    [StoreState.bookId]: null,
    [StoreState.book]: null
}