<template>
  <div>
    <main-nav :color="'black'" />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Admin</h2>
        </div>
      </div>
      <div class="row">
        <admin-nav :active="activeRoute"/>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
          <div class="secondary ml30 curved mr20">
            <h2 v-if="word">{{word.name}}</h2>
            <h3>Questions</h3>

          <router-link :to="createQuestionLink">
            <button class="btn btn-default mt10 mb20">
              <i class="fa fa-plus"/> Add Question
            </button>
          </router-link>

            <sync-loader
              :loading="loading"
              :color="`#f35b2c`"
              :size="'15px'"
              style="margin-top: 20px"
            ></sync-loader>

            <div class="row mt20" v-if="error">
              <div class="col-sm-12">
                <div class="alert" role="alert">
                  {{ error }}
                </div>
              </div>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                    <th>Title</th>
                    <th>Subtitle</th>
                    <th>Question</th>
                    <th>Type</th>
                    <th>Sort</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="question in questions" :key="question.id">
                  <td>{{question.title}}</td>
                  <td>{{question.subTitle}}</td>
                  <td>{{question.question}}</td>
                  <td>{{question.type}}</td>
                  <td>{{question.sort}}</td>
                  <td>
                    <router-link :to="editLink(question)">
                      <button class="btn btn-default">
                        <i class="fa fa-pencil" />
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/questions/state";
import { StoreMutations as mutations } from "@/store/admin/questions/mutations";
import { StoreActions as actions } from "@/store/admin/questions/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";

export default {
  name: "AdminQuestions",
  components: {
    MainNav,
    SyncLoader,
    AdminNav
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      } else {
        let wordId = this.$route.params.wordId;
        this.commit(mutations.SET_WORD_ID, wordId);
        this.dispatch(actions.getQuestions);
        this.dispatch(actions.getWord);
      }
    });
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      adminLinks: routes.adminLinks()
    };
  },
  computed: {
    ...mapState(StorePaths.adminQuestions, [
      state.loading, state.questions, state.error, state.word
    ]),
    activeRoute() {
      return routes.admin.words.name;
    },
    createQuestionLink() {
      return {
        name: routes.admin.questionCreate.name,
        params: {wordId: this.$route.params.wordId}
      }
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminQuestions}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminQuestions}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    editLink(question) {
      return {
        name: routes.admin.questionEdit.name,
        params: {
          questionId: question.id
        }
      }
    }
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

nav {
  margin-bottom: 0px;
}

td {
  text-align: left;
}

.curved {
  border-radius: 10px;
}

.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

img, .word {
  border-radius: 10px;
}
</style>