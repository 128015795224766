
import School from "@/adapters/School";
import AdminSchoolRepository from "@/API/AdminSchoolRepository";
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getSchools: 'getSchools',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getSchools] ({ commit }) {
        AdminSchoolRepository.getAll().then((data) => {
            let schools = data.schools.map(it => {
                return new School(it);
            })
            commit(StoreMutations.SET_SCHOOLS, schools);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
    }
}