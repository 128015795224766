export const StoreState = {
    loading: 'loading',
    error: 'error',
    schools: 'schools',
    profiles: 'profiles'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.schools]: [],
    [StoreState.profiles]: []
}