<template>
  <div>
    <div class="row mt20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-sm-offset-3" v-if="step == 1">
      <div class="form-group">
        <label
          >Access Code
          <i
            v-tooltip="
              'You should have received this in a book or in a doctors office'
            "
            class="fa fa-info-circle"
          />
        </label>
        <input
          class="form-control"
          v-model="accessCode"
          placeholder="123456"
          :disabled="loading"
          v-on:keyup.enter="accessCodeNext"
        />
        <br />
        <button
          style="width: 100%; border-radius: 10px"
          class="btn btn-primary"
          @click="accessCodeNext"
        >
          Next
        </button>
      </div>
      
      <div v-if="requiresAccount">
        <p>Already have an account?</p>
        <button
          style="border-radius: 10px; font-size: large; width: 200px"
          class="btn btn-default"
          @click="loginClick"
        >
          Login
        </button>
      </div>
    </div>

    <div class="col-sm-6 col-sm-offset-3" v-if="step == 2 && requiresAccount">
      <div class="form-group">
        <label>First Name</label>
        <input
          class="form-control"
          v-model="firstName"
          placeholder="Steve"
          :disabled="loading"
          v-on:keyup.enter="submitRegister"
        />
      </div>

      <div class="form-group">
        <label>Last Initial</label>
        <input
          class="form-control"
          v-model="lastName"
          placeholder="S"
          :disabled="loading"
          v-on:keyup.enter="submitRegister"
        />
      </div>

      <div class="form-group">
        <label>Email</label>
        <input
          class="form-control"
          type="email"
          v-model="email"
          placeholder="me@example.com"
          :disabled="loading"
          v-on:keyup.enter="submitRegister"
        />
      </div>

      <div class="form-group">
        <label>Create Password</label>
        <input
          class="form-control"
          type="password"
          v-model="password"
          placeholder="..."
          :disabled="loading"
          v-on:keyup.enter="submitRegister"
        />
      </div>

      <button
        style="border-radius: 10px"
        :disabled="loading"
        @click="submitRegister"
        class="btn btn-primary btn-login"
      >
        Register
      </button>
      <br />
    </div>

    <div class="col-sm-6 col-sm-offset-3" v-if="step == 3 && requiresAccount">
      <h3>Login</h3>
      <div class="form-group">
        <label>Email</label>
        <input
          class="form-control"
          type="email"
          v-model="email"
          placeholder="me@example.com"
          :disabled="loading"
          v-on:keyup.enter="loginSubmit"
        />
      </div>

      <div class="form-group">
        <label>Password</label>
        <input
          class="form-control"
          type="password"
          v-model="password"
          placeholder="..."
          :disabled="loading"
          v-on:keyup.enter="loginSubmit"
        />
      </div>

      <button
        :disabled="loading"
        @click="loginSubmit"
        class="btn btn-primary btn-login"
      >
        Login
      </button>
      <br><br><br>

      <router-link class="orange" :to="forgotPasswordRoute">
        Forgot Password?
      </router-link>
    </div>

    <div class="col-sm-12" v-if="step > 1">
        <button
        style="border-radius: 10px"
        @click="step = 1"
        class="btn btn-default btn-login mt20"
        >
        Back
        </button>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <a href="https://api.app.thelandofcan.com/public/privacy.html" target="_blank" style="margin-top: 20px; color: #f35b2c;">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { StoreActions as RegisterActions } from "@/store/register/actions";
import { StoreState as RegisterState } from "@/store/register/state";
import { StoreMutations as RegisterMutations } from "@/store/register/mutations";

import { StoreActions as LoginActions } from "@/store/login/actions";
import { StoreState as LoginState } from "@/store/login/state";
import { StoreMutations as LoginMutations } from "@/store/login/mutations";
import routes from '@/routes';

export default {
  name: "UserOnboarding",
  props: {
    requiresAccount: {
      default: false
    }
  },
  data() {
    return {
      step: 1,
      accessCode: "",
      registerClassroomId: null,
      registerDoctorId: null,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    };
  },
  computed: {
    loading() {
      return (
        this.$store.state[`${StorePaths.register}`][RegisterState.loading] ||
        this.$store.state[`${StorePaths.login}`][LoginState.loading]
      );
    },
    error() {
      let registerError =
        this.$store.state[`${StorePaths.register}`][RegisterState.error];
      if (registerError) {
        return registerError;
      }

      let loginError =
        this.$store.state[`${StorePaths.login}`][LoginState.error];
      if (loginError) {
        return loginError;
      }
      return null;
    },
    forgotPasswordRoute() {
      return {
        name: routes.forgotPassword.name
      }
    }
  },
  methods: {
    registerClick() {
      this.step = 1;
    },
    loginClick() {
      this.step = 3;
    },
    accessCodeNext() {
      var success = (code) => {
        if(!this.requiresAccount) {
          this.$setAccessCode(code.accessCode)
          this.$setSlug(code.homepageSlug)
          this.$router.push(routes.home)
        } else {
          this.step = 2;
          this.registerDoctorId = code.doctorId;
          this.registerClassroomId = code.classroomId;
        }
      };

      this.registerDispatch(RegisterActions.checkCode, {
        code: this.accessCode,
        callback: success,
      });
    },
    submitRegister() {
      this.registerCommit(RegisterMutations.SET_ERROR, null);
      this.registerDispatch(RegisterActions.register, {
        accessCode: this.accessCode,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        registerDoctorId: this.registerDoctorId,
        registerClassroomId: this.registerClassroomId,
      });
    },
    loginSubmit() {
      this.loginCommit(LoginMutations.SET_ERROR, null);
      this.loginDispatch(LoginActions.login, {
        email: this.email,
        password: this.password,
      });
    },
    registerCommit(key, val) {
      this.$store.commit(`${StorePaths.register}/${key}`, val);
    },
    registerDispatch(key, val) {
      this.$store.dispatch(`${StorePaths.register}/${key}`, val);
    },
    loginCommit(key, val) {
      this.$store.commit(`${StorePaths.login}/${key}`, val);
    },
    loginDispatch(key, val) {
      this.$store.dispatch(`${StorePaths.login}/${key}`, val);
    },
  },
};
</script>

<style scoped>
.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.btn {
    border-radius: 10px;
}

.form-group {
  background: white;
}
</style>