import AdminQuestion from '@/adapters/AdminQuestion';
import AdminQuestionRepository from '@/API/AdminQuestionRepository';
import app from '@/main';
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getQuestion: 'getQuestion',
    updateQuestion: 'updateQuestion',
    createQuestion: 'createQuestion',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getQuestion] ({ commit, state }) {
        commit(StoreMutations.SET_LOADING, true);

        AdminQuestionRepository.get(state.questionId).then((data) => {
            let question = new AdminQuestion(data.question);
            commit(StoreMutations.SET_QUESTION, question);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        })
    },
    [StoreActions.createQuestion] ({ commit }, data) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, true);
        AdminQuestionRepository.create(data).then(() => {
            app.config.globalProperties.$notify({
                type: 'success',
                title: 'Question Saved'
              });
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
            app.config.globalProperties.$notify({
                type: 'danger',
                title: 'Failed to create question'
            });
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        })
    },
    [StoreActions.updateQuestion] ({ commit, state }, data) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, true);
        AdminQuestionRepository.update(state.questionId, data).then(() => {
            app.config.globalProperties.$notify({
                type: 'success',
                title: 'Question Saved'
              });
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
            app.config.globalProperties.$notify({
                type: 'danger',
                title: 'Failed to update question'
            });

        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        })
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_QUESTION, null);
        commit(StoreMutations.SET_QUESTION_ID, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
    }
}