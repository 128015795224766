<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div v-if="book">
              <iframe  :src="book.link" width="100%"
              height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay;
              clipboard-read; clipboard-write"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/healthbooks/state";
import { StoreActions as actions } from "@/store/healthbooks/actions";
import { StoreMutations as mutations } from "@/store/healthbooks/mutations";
export default {
  name: "14Can",
  components: {
    SyncLoader,
    MainNav
  },
  mounted() {
    let bookId = this.$route.params.bookId;
    this.commit(mutations.SET_BOOK_ID, bookId);
    this.dispatch(actions.getBook);
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  computed: {
    ...mapState(StorePaths.healthbooks, [state.loading, state.error, state.book]),
    profileRoute() {
      return {
        name: routes.myProfile.name
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.healthbooks}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.healthbooks}/${key}`, val);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}

.book {
  background: #efefef;
  border-radius: 10px;
  margin: 10px;
}

.btn-primary {
  border-radius: 10px;
}

.book > img {
  height: 200px;
  width: auto;
}
</style>