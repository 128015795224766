<template>
  <div>
    <main-nav :switchProfile="true" />
    <div class="container-full primary">

      <div class="row mt20">
        <div class="col-sm-12">
          <h2>My Profile</h2>
        </div>
      </div>
      <sync-loader
        :loading="loading"
        :color="`#f35b2c`"
        :size="'15px'"
        style="margin-top: 20px"
      ></sync-loader>

      <div class="row mt20" v-if="error">
        <div class="col-sm-12">
          <div class="alert" role="alert">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="row mt20" v-if="user">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
          <div class="secondary ml30 curved mr20">
            <h3 class="mb20">{{ user.firstName }} {{ user.lastName }}</h3>



            <div class="primary curved p10">
              <label class="mr10">Type</label>
              <p class="text-right">{{ user.type }}</p>
            </div>

            <div class="primary curved p10" v-if="user.type == 'Educator'">
              <label class="mr10">School District</label>
              <p class="text-right">{{ user.schoolDistrict }}</p>
            </div>

            <div class="primary curved p10" v-if="user.type == 'Educator'">
              <label class="mr10">School</label>
              <p class="text-right">{{ user.school }}</p>
            </div>

            <div class="primary curved p10" v-if="user.type == 'Educator' && user.classroom">
              <label class="mr10">Classroom</label>
              <p class="text-right">{{ user.classroom.name }}</p>
            </div>

            <div class="primary curved p10" v-if="user.type == 'Educator'">
              <label class="mr10">Grade</label>
              <p class="text-right">{{ user.grade }}</p>
            </div>

            <div class="primary curved p10" v-if="user.type == 'Educator'">
              <label class="mr10">Classroom Code</label>
              <p class="text-right">{{ user.accessCode }}</p>
            </div>

            <hr>

            <h5>Email Settings</h5>

            <div class="primary curved p10">
              <label class="mr10">Completed Word</label>
              <p class="text-right">{{ user.completedWordEmail ? 'Yes' : 'No' }}</p>
            </div>

            <div class="primary curved p10">
              <label class="mr10">Completed All Words</label>
              <p class="text-right">{{ user.completedAllWordsEmail ? 'Yes' : 'No' }}</p>
            </div>

            <hr>

            <div class="primary curved p10">
              <router-link :to="canboardRoute">
                <p>Canboard</p>
              </router-link>
            </div>

            <hr>

            <div class="primary curved p10">
              <router-link :to="completeProfileRoute">
                <p><i class="fa fa-pencil" style="margin-right:5px;" /> Edit</p>
              </router-link>
            </div>

            <div class="primary curved p10 loc-bg" v-if="!user.completedProfile">
              <router-link :to="completeProfileRoute">
                <p class="white pointer">Complete Profile Setup</p>
              </router-link>
            </div>

            <div class="primary curved p10">
              <p @click="manageAccount" class="pointer loc">
                Manage Account</p>
            </div>

            <div class="primary curved p10 loc-bg">
              <p @click="logout" class="white pointer">Logout</p>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
          <div class="secondary ml30 curved mr20">
            <h3>Student Profiles</h3>
            <p>Students that belong to your profile</p>

            <router-link :to="bulkRoute">
              <button class="btn btn-default mt20 mb20 bulk-btn">
                <i class="fa fa-plus fa-lg mr5" />
                Bulk Add
              </button>
            </router-link>

            <div class="row">
              <div class="col-sm-3">
                <div
                  class="ml10 mr10 primary curved add-card"
                  @click="addProfileClicked"
                >
                  <i class="fa fa-plus fa-lg mt10 loc" />
                  <h4>Add</h4>
                </div>
              </div>

              <div
                class="col-sm-3"
                v-for="profile in user.profiles"
                :key="profile.id"
                @click="editProfile(profile.id)"
              >
                <div class="ml10 mr10 primary curved card">
                  {{ profile.firstName }} {{ profile.lastInitial }}
                  <router-link v-if="user && user.type == 'Parent'" style="font-weight: bold;font-size:medium;margin-left: 10px;" :to="switchProfilesRoute"><i class="fa fa-share-square-o" /></router-link>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <br>
                <div>
                  <p>Your students can login with this link:</p>
                  <a class="orange" :href="`https://app.thelandofcan.com/login/student/${user.accessCode}`">
                  https://app.thelandofcan.com/login/student/{{user.accessCode}}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="secondary ml30 curved mr20">
            <h3>Recently Completed</h3>

              <sync-loader
              :loading="recentlyCompletedLoading"
              :color="`#f35b2c`"
              :size="'15px'"
              style="margin-top: 20px"
            ></sync-loader>

            <div class="row">
              <div class="col-sm-12">
                <table class="table table-striped text-left">
                  <thead>
                    <tr>
                      <th>Profile</th>
                      <th>Word</th>
                      <th>Time Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in recentlyCompleted" :key="item.id" @click="clickedCompleted(item)">
                      <td>{{item.name()}}</td>
                      <td>{{item.wordName}}</td>
                      <td>{{item.ts}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>

    <profile-modal
      :open="showProfileModal"
      :id="editProfileId"
      @changed="profileModalChanged"
    />

    <completed-word-info
    :open="selectedCompletedInfo != null"
    :item="selectedCompletedInfo"
    @changed="completeModalChanged"
    />

    <pending-verify-email-modal :showing="showingPendingEmailModal" @closed="closedPendingEmail"  />
    <manage-account-modal :showing="showManage" @closed="showManage = false"  />
  </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/myProfile/state";
import { StoreMutations as mutations } from "@/store/myProfile/mutations";
import { StoreActions as actions } from "@/store/myProfile/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import ProfileModal from "@/components/user/ProfileModal.vue";
import CompletedWordInfo from '@/components/user/CompletedWordInfo.vue';
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import PendingVerifyEmailModal from '@/components/user/PendingVerifyEmailModal.vue';
import ManageAccountModal from '@/components/user/ManageAccountModal.vue';
import MainNav from '../MainNav.vue';
export default {
  name: "CompleteProfile",
  components: {
    ProfileModal,
    SyncLoader,
    CompletedWordInfo,
    PendingVerifyEmailModal,
    ManageAccountModal,
    MainNav
  },
  mounted() {
    this.fetch();

    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if(user) {

        if(!user.emailConfirmed) {
          this.$showPendingEmailModal()
        }
      }
    });
  },
  data() {
    return {
      showProfileModal: false,
      editProfileId: null,
      selectedCompletedInfo: null,
      showManage: false
    };
  },
  computed: {
    ...mapState(StorePaths.myProfile, [state.loading, state.error, state.user, state.recentlyCompleted, state.recentlyCompletedLoading]),
    bulkRoute() {
      return { name: routes.bulkAddProfiles.name };
    },
    completeProfileRoute() {
      return {name: routes.completeProfile.name };
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    showingPendingEmailModal() {
      return this.$isPendingEmailModalShowing();
    },
    canboardRoute() {
      return {
        name: routes.selectCanboard.name
      }
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.myProfile}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.myProfile}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    editProfile(id) {
      this.editProfileId = id;
      this.showProfileModal = true;
    },
    profileModalChanged(val) {
      this.showProfileModal = val;
      if (!val) {
        this.editProfileId = null;
        this.fetch();
      }
    },
    completeModalChanged(val) {
      if(!val) {
        this.selectedCompletedInfo = null;
      }
    },
    addProfileClicked() {
      this.showProfileModal = true;
    },
    fetch() {
      this.commit(mutations.SET_LOADING, true);
      this.$store.dispatch(GlobalActions.getUser, (user) => {
        this.commit(mutations.SET_LOADING, false);
        if (!user) {
          this.$logout();
          return;
        }
        this.commit(mutations.SET_USER, user);
      });

      this.dispatch(actions.getRecentlyCompleted);
    },
    clickedCompleted(item) {
      this.selectedCompletedInfo = item;
    },
    closedPendingEmail() { },
    manageAccount() {
      this.showManage = true
    }
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

a {
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}
</style>