export const StoreState = {
    loading: 'loading',
    error: 'error',
    grades: 'grades',
    schools: 'schools'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.grades]: [],
    [StoreState.schools]: []
}