<template>
  <Modal v-model="show" :title="`${this.id ? 'Edit' : 'Add'} Student Profile`">
    <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px; margin-bottom: 20px"
    ></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="form-group" v-if="!loading">
      <label>First Name</label>
      <input
        class="form-control"
        placeholder="Steve"
        v-model="firstName"
        @keyup.enter="saveClick"
      />
    </div>
    <div class="form-group" v-if="!loading">
      <label>Last Initial</label>
      <input
        class="form-control"
        placeholder="G"
        maxlength="1"
        v-model="lastInitial"
        @keyup.enter="saveClick"
      />
    </div>
    <div class="form-group" v-if="!loading">
      <label>Password</label>
      <input
        class="form-control"
        placeholder="..."
        v-model="passcode"
        @keyup.enter="saveClick"
      />
    </div>
    <template #footer>
      <div>
        <Btn @click="show = false">Cancel</Btn>
        <Btn type="primary" @click="saveClick" :disabled="loading">Save</Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import ProfileRepository from "@/API/ProfileRepository";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import Profile from "@/adapters/Profile";

export default {
  name: "ProfileModal",
  mounted() {
    this.show = this.open;
  },
  components: {
    SyncLoader,
  },
  props: {
    open: {
      default: false,
    },
    id: {
      default: null,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: null,
      firstName: "",
      lastInitial: "",
      passcode: "",
    };
  },
  methods: {
    saveClick() {
      let data = {
        firstName: this.firstName,
        lastInitial: this.lastInitial,
        passcode: this.passcode == "" ? null : parseInt(this.passcode),
      };
      this.loading = true;
      this.error = null;
      if (this.id) {
        data['id'] = this.id;
        ProfileRepository.update(data)
          .then(() => {
            this.show = false;
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        ProfileRepository.create(data)
          .then(() => {
            this.show = false;
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getProfile() {
      this.loading = true;
      ProfileRepository.get(this.id)
        .then((data) => {
          let profile = new Profile(data.profile);
          this.firstName = profile.firstName;
          this.lastInitial = profile.lastInitial;
          this.passcode = profile.passcode;
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    show(newVal) {
      this.$emit("changed", newVal);
    },
    open(newVal) {
      this.show = newVal;
      if (newVal) {
        this.firstName = "";
        this.lastInitial = "";

        if (this.id) {
          this.getProfile();
        }
      }
    },
  },
};
</script>

<style>
.modal-content {
  border-radius: 15px;
}
</style>

<style scoped>
.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>