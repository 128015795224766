export const StoreState = {
    loading: 'loading',
    error: 'error',
    place: 'place',
    board: 'board',
    wordId: 'wordId',
    word: 'word',
    words: 'words'
}

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.place]: null,
    [StoreState.wordId]: null,
    [StoreState.word]: null,
    [StoreState.board]: [],
    [StoreState.words]: []
}