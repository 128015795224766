// import UserRepository from "@/API/UserRepository";
import GradesRepository from "@/API/GradesRepository";
import UserRepository from "@/API/UserRepository";
import routes from "@/routes";
import { StoreMutations } from "./mutations";
import app from '@/main';
import SchoolRepository from "@/API/SchoolRepository";

export const StoreActions = {
    getGrades: 'getGrades',
    save: 'save',
    getSchools: 'getSchools',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getGrades] ({commit}) {
        commit(StoreMutations.SET_LOADING, true);

        GradesRepository.get().then(data => {
            let grades = data.grades;
            commit(StoreMutations.SET_GRADES, grades);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.save] ({commit}, data) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.update(data).then(() => {
            app.config.globalProperties.$router.push({name: routes.myProfile.name});
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getSchools] ({commit}) {
        commit(StoreMutations.SET_LOADING, false);
        SchoolRepository.get().then((data) => {
            commit(StoreMutations.SET_SCHOOLS, data.schools);
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_GRADES, []);
        commit(StoreMutations.SET_SCHOOLS, []);
        commit(StoreMutations.SET_LOADING, false);
    }
}