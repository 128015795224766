import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_ACCESS_CODES: 'SET_ACCESS_CODES',
    SET_DOCTORS: 'SET_DOCTORS',
    SET_SCHOOLS: 'SET_SCHOOLS',
    SET_ERROR: 'SET_ERROR',
    SET_HOMEPAGES: 'SET_HOMEPAGES'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_ACCESS_CODES](state, value) {
        state[StoreState.accessCodes] = value;
    },
    [StoreMutations.SET_DOCTORS](state, value) {
        state[StoreState.doctors] = value;
    },
    [StoreMutations.SET_SCHOOLS](state, value) {
        state[StoreState.schools] = value;
    },
    [StoreMutations.SET_HOMEPAGES](state, value) {
        state[StoreState.homepages] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}