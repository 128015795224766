import Doctor from '@/adapters/Doctor';
import AdminDoctorRepository from '@/API/AdminDoctorRepository';
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getDoctors: 'getDoctors',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getDoctors] ({ commit }) {
        AdminDoctorRepository.getAll().then((data) => {
            let doctors = data.doctors.map(it => {
                return new Doctor(it);
            })
            commit(StoreMutations.SET_DOCTORS, doctors);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
    }
}