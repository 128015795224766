<template>
  <div>
    <main-nav />
    <div class="container-full primary">

    <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px"
    ></sync-loader>

    <div class="row mt20">
      <h2>Bulk Add Student Profiles</h2>
    </div>
    <div class="row mt20">
      <div class="col-sm-12">
        <button :disabled="loading" class="btn btn-primary" @click="saveClick">Save</button>
      </div>
    </div>

    <div class="row mt20">
      <div class="col-sm-12">
        <p>Passwords are highly encouraged, but are not required.</p>
      </div>
    </div>

    <div class="row mt20">
      <div class="col-xs-12 col-sm-12">
        <div class="ml30 curved mr20">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Initial</th>
                <th>Password</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(profile, index) in profiles" :key="index">
                <td>
                  <input
                   :disabled="loading"
                    class="form-control"
                    placeholder="First Name"
                    v-model="profile.firstName"
                  />
                  <p v-if="errors[index]" class="row-error">
                    {{errors[index]}}
                  </p>
                </td>
                <td>
                  <input
                   :disabled="loading"
                    class="form-control"
                    placeholder="Last Initial"
                    v-model="profile.lastInitial"
                    maxlength="1"
                  />
                </td>
                <td>
                  <input
                   :disabled="loading"
                    class="form-control"
                    placeholder="Password"
                    v-model="profile.passcode"
                  />
                </td>
                <td>
                  <button :disabled="loading" class="btn btn-danger" @click="removeProfile(index)">
                    <i class="fa fa-remove" />
                  </button>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <button :disabled="loading" class="btn btn-default" @click="addProfile()">
                    <i class="fa fa-plus primary" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt20">
      <div class="col-sm-12">
        <button :disabled="loading" class="btn btn-primary" @click="saveClick">Save</button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import Profile from "@/adapters/Profile";
import ProfileRepository from "@/API/ProfileRepository";

export default {
  name: "BulkAddProfiles",
  components: {
    MainNav,
    SyncLoader,
  },
  mounted() {},
  data() {
    return {
      profiles: [new Profile()],
      errors: {},
      loading: false
    };
  },
  methods: {
    logout() {
      this.$logout();
    },
    removeProfile(i) {
      this.profiles = this.profiles
        .slice(0, i)
        .concat(this.profiles.slice(i + 1, this.profiles.length));
    },
    addProfile() {
      this.profiles.push(new Profile());
    },
    saveClick() {
      this.createProfiles();
    },
    createProfiles() {
      this.errors = {};
      this.loading = true;
      let numSaving = this.profiles.length;
      var numComplete = 0;
      var profileSaved = false;
      
      this.profiles.forEach((profile, index) => {
        let data = {
          firstName: profile.firstName,
          lastInitial: profile.lastInitial,
          passcode: profile.passcode == "" ? null : parseInt(profile.passcode),
        };

        ProfileRepository.create(data)
          .then(() => {
            this.removeProfile(index);
            profileSaved = true;
          })
          .catch((err) => {
            this.errors[index] = err;
            this.$forceUpdate();
          }).finally(() => {
            numComplete += 1;
            if(numComplete == numSaving) {
              this.loading = false;
            }

            if(profileSaved){
              this.$notify({
                type: 'success',
                title: 'Profiles Saved'
              });
            }
          });
      });
    }
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

.row-error {
  padding-top: 10px;
  float: left;
  padding-left: 3px;
  color: #f35b2c;
}
</style>