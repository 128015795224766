import CompletedQuestionInfo from "./CompletedQuestionInfo";

export default class CompletedWordInfo {
    constructor(adapter = {
        questions: [],
        duration: ""
    }) {
        this.questions = adapter.questions.map(it => {
            return new CompletedQuestionInfo(it);
        });
        this.duration = adapter.duration;
    }
}