import { StoreMutations } from "./mutations";
import Healthbook from "@/adapters/Healthbook";
import HealthbookRepository from "@/API/HealthbookRepository";

export const StoreActions = {
    getBooks: 'getBooks',
    getBook: 'getBook',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getBooks] ({ commit }) {
        commit(StoreMutations.SET_LOADING, true);

        HealthbookRepository.getAll().then( res => {
            let books = res.books.map(it => {
                return new Healthbook(it);
            })
            commit(StoreMutations.SET_BOOKS, books);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getBook] ({ commit, state }) {
        commit(StoreMutations.SET_LOADING, true);

        HealthbookRepository.get(state.bookId).then( res => {
            let book = new Healthbook(res.book);
            commit(StoreMutations.SET_BOOK, book);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_BOOK, null);
        commit(StoreMutations.SET_BOOK_ID, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_BOOKS, []);
    }
}