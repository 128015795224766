export const StoreState = {
    loading: 'loading',
    words: 'words',
    nextUnlock: 'nextUnlock'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.nextUnlock]: null,
    [StoreState.words]: [],
}