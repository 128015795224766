export default class CompletedQuestionInfo {
    constructor(adapter = {
        id: 0,
        title: "",
        subTitle: "",
        question: "",
        type: "",
        answers: []
    }) {
        this.id = adapter.id;
        this.title = adapter.title;
        this.subTitle = adapter.subTitle;
        this.question = adapter.question;
        this.type = adapter.type;
        this.answers = adapter.answers;
    }
}