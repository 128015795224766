<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-12 mt20" v-if="passport">
          <img class="passport-img" :src="passport.link" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from '@/store/StorePaths';
import { mapState } from 'vuex';
import { StoreState as state } from '@/store/home/state';
import { StoreActions as actions } from '@/store/home/actions'; 
import { StoreActions as GlobalActions } from '@/store/global/actions';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';
export default {
  name: "CanPassport",
  components: {
    SyncLoader,
    MainNav
  },
  mounted() {
    if(!this.$hasSession()) {
      this.$logout("can");
      return;
    }

    this.$store.dispatch(GlobalActions.getSessionDetails, (session) => {
      this.userProfile = !session.studentProfile;
      this.studentProfile = session.studentProfile;
    });

    this.dispatch(actions.getPassport);
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  computed: {
    ...mapState(StorePaths.home, [
      state.loading,
      state.passport
    ]),
    profileRoute() {
      if(this.userProfile) {
        return {
          name: routes.myProfile.name
        } 
      } else {
        return {
          name: routes.myStudentProfile.name
        } 
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    }
  },
  data() {
    return {
      userProfile: false,
      studentProfile: false
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.home}/${key}`,val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.home}/${key}`,val);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

@media(max-width:767px) {
  .passport-img {
    width: 100%;
  }
}

@media(min-width: 767px) {
  .passport-img {
    height: 500px;
  }
}
</style>