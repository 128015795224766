import { StoreMutations } from "./mutations";
import FoodAllergyResource from "@/adapters/FoodAllergyResource";
import FoodAllergyResourcesRepository from "@/API/FoodAllergyResourcesRepository";

export const StoreActions = {
    getResources: 'getResources',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getResources] ({ commit }) {
        commit(StoreMutations.SET_LOADING, true);

        FoodAllergyResourcesRepository.getAll().then( res => {
            let books = res.resources.map(it => {
                return new FoodAllergyResource(it);
            })
            commit(StoreMutations.SET_RESOURCES, books);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_RESOURCES, []);
    }
}