import { StoreMutations } from "./mutations";
import SchoolRepository from "@/API/SchoolRepository";
import ProfileRepository from "@/API/ProfileRepository";
import Profile from "@/adapters/Profile";
export const StoreActions = {
    getSchools: 'getSchools',
    getProfilesBySchoolClassroom: 'getProfilesBySchoolClassroom',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getSchools] ({commit}) {
        commit(StoreMutations.SET_LOADING, false);
        SchoolRepository.get().then((data) => {
            commit(StoreMutations.SET_SCHOOLS, data.schools);
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getProfilesBySchoolClassroom] ({commit}, req) {
        commit(StoreMutations.SET_LOADING, true);

        ProfileRepository.getBySchoolClassroom(
            req.schoolId, req.classroomId
        ).then(data => {
            let profiles = data.profiles.map(it => {
                return new Profile(it);
            });
            commit(StoreMutations.SET_PROFILES, profiles);
        })
        .catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_PROFILES, []);
        commit(StoreMutations.SET_SCHOOLS, []);
    }
}