import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_QUESTIONS: 'SET_QUESTIONS',
    SET_WORD_ID: 'SET_WORD_ID',
    SET_WORD: 'SET_WORD',
    SET_ERROR: 'SET_ERROR'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_QUESTIONS](state, value) {
        state[StoreState.questions] = value;
    },
    [StoreMutations.SET_WORD_ID](state, value) {
        state[StoreState.wordId] = value;
    },
    [StoreMutations.SET_WORD](state, value) {
        state[StoreState.word] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}