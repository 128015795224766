export const StoreState = {
    loading: 'loading',
    stats: 'stats',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.stats]: {},
    [StoreState.error]: null
}