<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Manage Account"
      @hide="callback"
      :footer="false"
    >

        <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px"
    ></sync-loader>

    <div class="row mt20 mb20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="row mt20 mb20" v-if="msg">
      <div class="col-sm-12">
        <div class="alert-msg" role="alert" style="padding: 10px;">
          {{ msg }}
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 secondary curved">
            <h4>Export Account</h4>
            <p>You will receive an email containing your account data.</p>
            <button class="btn btn-default" @click="exportAccount">Export Account</button>
            <br><br>
        </div>
    </div>
    <br><br>

    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 secondary curved">
            <h4>Delete Account</h4>
            <p>This action can't be undone.</p>

            <button class="btn btn-danger" @click="showingDelete = true">Delete Account</button>
            <br><br>

            <div v-if="showingDelete">
                <h4>Delete</h4>
                <p style="font-weight: bold;">Are you sure you want to delete your account? <br>This action can't be undone.</p>

                <button class="btn btn-danger mr10" @click="deleteAccount">Delete Account</button>
                <button class="btn btn-default" @click="showingDelete = false">Cancel</button>
            </div>
        </div>
    </div>

  </modal>
</template>

<script>
import UserRepository from '@/API/UserRepository'
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
    name: "ManageAccountModal",
    components: {
        SyncLoader
    },
    props: {
        showing: {
            default: false
        }
    },
    data(){ 
        return {
            open: false,
            showingDelete: false,
            loading: false,
            error: null,
            msg: null
        }
    },
    methods: {
        callback() {
            this.$emit("closed")
        },
        exportAccount() {
            this.loading = true;
            this.error = null;

            UserRepository.export().then(() => {
                this.msg = "Account Exported! You will receive an email shortly."
            }).catch(err => {
                this.error = err;
            }).finally(() => {
                this.loading = false;
            })

        },
        deleteAccount() {
            this.loading = true;
            this.error = null;

            UserRepository.delete().then(() => {
                this.$logout()
            }).catch(err => {
                this.error = err;
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style scoped>
.alert-msg {
  background: #efefef;
  color: #f35b2c !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>