export const StoreState = {
    loading: 'loading',
    accessCodes: 'accessCodes',
    doctors: 'doctors',
    schools: 'schools',
    error: 'error',
    accessCode: 'accessCode',
    allowedUses: 'allowedUses',
    homepages: 'homepages'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.accessCodes]: [],
    [StoreState.doctors]: [],
    [StoreState.schools]: [],
    [StoreState.homepages]: [],
    [StoreState.error]: null,
    [StoreState.accessCode]: null,
    [StoreState.allowedUses]: null
}