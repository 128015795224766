<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row mt20">
        <div
          class="col-xs-12 col-sm-8 col-md-4 col-sm-offset-2 col-md-offset-4"
        >
          <img
            :src="canLogo"
            width="100"
            class="mt20 ml10"
          />
          <br /><br />
          <h2>Login</h2>
          <p>	if your child was provided a classroom code by their teacher, please visit the student login page</p>
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <div v-if="destination == 'can'">
            <h4>Login or create an account to keep track of your progress as you go through the 14 Words of Can!</h4>
          </div>

          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="email"
              v-model="email"
              placeholder="me@example.com"
              :disabled="loading"
              v-on:keyup.enter="loginClick"
            />
          </div>

          <div class="form-group">
            <label>Password</label>
            <input
              class="form-control"
              type="password"
              v-model="password"
              placeholder="..."
              :disabled="loading"
              v-on:keyup.enter="loginClick"
            />
          </div>

          <button
            :disabled="loading"
            @click="loginClick"
            class="btn btn-primary btn-login"
          >
            Login
          </button>
          
          
          <router-link :to="registerRoute">
            <button class="btn btn-default btn-register">Register</button>
          </router-link>

          <router-link :to="forgotPasswordRoute">
            <p class="btn-forgot orange">Forgot password?</p>
          </router-link>

          <a href="https://api.app.thelandofcan.com/public/privacy.html" target="_blank" style="margin-top: 20px; color: #f35b2c;">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/login/state";
import { StoreActions as actions } from "@/store/login/actions";
import { StoreMutations as mutations } from "@/store/login/mutations";
import CanLogo from '@/assets/images/canlogo.png';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import MainNav from '@/components/MainNav.vue';
export default {
  name: "UserLogin",
  components: {
    SyncLoader,
    MainNav
  },
  mounted() {
    if (this.$hasSession()) {
      this.$router.push({name: routes.home.name });
      return
    }

    let destination = this.$route.query.destination;
    this.commit(mutations.SET_DESTINATION, destination);
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      email: "",
      password: "",
      canLogo: CanLogo
    };
  },
  computed: {
    ...mapState(StorePaths.login, [state.loading, state.error, state.destination]),
    registerRoute() {
      return {
        name: routes.register.name,
        query: {
          email: this.email,
          destination: this.destination
        },
      };
    },
    studentRoute() {
      return {
        name: routes.studentLogin.name
      };
    },
    forgotPasswordRoute() {
      return {
        name: routes.forgotPassword.name
      }
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.login}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.login}/${key}`, val);
    },
    loginClick() {
      this.commit(mutations.SET_ERROR, null);
      this.dispatch(actions.login, {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  background: #efefef;
  border-radius: 15px;
  padding: 10px;
}

input {
  border-radius: 10px !important;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.btn-login {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
}

.btn-register {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
  margin-top: 20px;
}

.btn-forgot {
  margin-top: 20px;
}

.logo {
  height: 50px;
  margin-top: 10px;
}

.container-full {
  padding: 0px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>