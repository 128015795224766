
import AccessCode from "@/adapters/AccessCode";
import Doctor from "@/adapters/Doctor";
import Home from "@/adapters/Home";
import School from "@/adapters/School";
import AdminAccessCodeRepository from "@/API/AdminAccessCodeRepository";
import AdminDoctorRepository from "@/API/AdminDoctorRepository";
import AdminHomeRepository from "@/API/AdminHomeRepository";
import AdminSchoolRepository from "@/API/AdminSchoolRepository";
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getAccessCodes: 'getAccessCodes',
    getAccessCode: 'getAccessCode',
    createAccessCode: 'createAccessCode',
    updateAccessCode: 'updateAccessCode',
    getDoctors: 'getDoctors',
    getSchools: 'getSchools',
    getHomepages: 'getHomepages',
    delete: 'delete',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getAccessCodes] ({ commit }) {
        AdminAccessCodeRepository.getAll().then((data) => {
            let accessCodes = data.accessCodes.map(it => {
                return new AccessCode(it);
            })
            commit(StoreMutations.SET_ACCESS_CODES, accessCodes);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.getAccessCode] ({ commit }, props) {
        commit(StoreMutations.SET_LOADING, true);

        AdminAccessCodeRepository.get(props.id)
        .then((data) => {
          let accessCode = new AccessCode(data.accessCode);
          props.callback(accessCode);
        })
        .catch((err) => {
            commit(StoreMutations.SET_ERROR, err);
        })
        .finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.createAccessCode] ({ commit }, props) {
        commit(StoreMutations.SET_LOADING, true);
        AdminAccessCodeRepository.create(props.data)
          .then(() => {
            props.callback();
          })
          .catch((err) => {
            commit(StoreMutations.SET_ERROR, err);
          })
          .finally(() => {
            commit(StoreMutations.SET_LOADING, false);
          });
    },
    [StoreActions.updateAccessCode] ({ commit }, props) {
        commit(StoreMutations.SET_LOADING, true);
        AdminAccessCodeRepository.update(props.id, props.data)
          .then(() => {
            props.callback();
          })
          .catch((err) => {
            commit(StoreMutations.SET_ERROR, err);
          })
          .finally(() => {
            commit(StoreMutations.SET_LOADING, false);
          });
    },
    [StoreActions.delete] ({ commit, dispatch }, id) {
        commit(StoreMutations.SET_LOADING, true);
        AdminAccessCodeRepository.delete(id)
          .then(() => {
            dispatch(StoreActions.getAccessCodes);
          })
          .catch((err) => {
            commit(StoreMutations.SET_ERROR, err);
          })
          .finally(() => {
            commit(StoreMutations.SET_LOADING, false);
          });
    },
    [StoreActions.getDoctors] ({commit}) {
        AdminDoctorRepository.getAll().then(data => {
            let doctors = data.doctors.map(it => {
                return new Doctor(it);
            })
            commit(StoreMutations.SET_DOCTORS, doctors);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        });
    },
    [StoreActions.getSchools] ({commit}) {
        AdminSchoolRepository.getAll().then(data => {
            let schools = data.schools.map(it => {
                return new School(it);
            })
            commit(StoreMutations.SET_SCHOOLS, schools);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        });
    },
    [StoreActions.getHomepages] ({commit}) {
        AdminHomeRepository.getAllUnassigned().then(data => {
            let homepages = data.items.map(it => {
                return new Home(it);
            })
            commit(StoreMutations.SET_HOMEPAGES, homepages);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        });
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_ACCESS_CODES, []);
        commit(StoreMutations.SET_DOCTORS, []);
        commit(StoreMutations.SET_SCHOOLS, []);
        commit(StoreMutations.SET_HOMEPAGES, []);
    }
}