<template>
  <div>
    <main-nav :color="'black'" />
    <div class="container-full primary" ref="container">
      <div class="row">
        <div class="col-sm-12">
          <h2>Admin</h2>
        </div>
      </div>
      <div class="row">
        <admin-nav :active="activeRoute" />

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
          <div class="secondary ml30 curved mr20">
            <h3>Create Homepage</h3>

            <sync-loader
              :loading="loading"
              :color="`#f35b2c`"
              :size="'15px'"
              style="margin-top: 20px"
            ></sync-loader>

            <div class="row mt20" v-if="error">
              <div class="col-sm-12">
                <div class="alert" role="alert">
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row" v-if="!loading">
              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group" v-if="!loading">
                  <label>Load Default</label>
                  <div class="form-group defaults">
                    <button v-for="defaultKey in defaultKeys" :key="defaultKey" @click="loadDefault(defaultKey)" class="btn btn-default">{{defaultKey}}</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row" v-if="!loading">
              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group" v-if="!loading">
                  <label>Doctor</label>
                  <select class="form-control" v-model="form.doctorId">
                    <option :value="null">None</option>
                    <option
                      v-for="doctor in doctors"
                      :key="doctor.id"
                      :value="doctor.id"
                    >
                      {{ doctor.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div> -->

            <div class="row" v-if="!loading">
              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Link *</label>
                  <input
                    v-model="form.slug"
                    class="form-control"
                    :disabled="mode != 'create'"
                  />
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Title</label>
                  <input v-model="form.title" class="form-control" />
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Title2</label>
                  <input v-model="form.title2" class="form-control" />
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Title3</label>
                  <input v-model="form.title3" class="form-control" />
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Image URL</label>
                  <input v-model="form.image" class="form-control" />
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <div class="form-group">
                  <label>Description</label>
                  <textarea v-model="form.desc" class="form-control"></textarea>
                </div>
              </div>

              <div class="col-sm-6 col-sm-offset-3">
                <h4>Sections</h4>

                <div
                  class="col-sm-12"
                  v-for="(section, index) in form.sections"
                  :key="index"
                >
                  <div class="col-sm-12 section">
                    <div class="form-group">
                      <label>Image</label>
                      <input v-model="section.image" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label>Title</label>
                      <input v-model="section.title" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        v-model="section.desc"
                        class="form-control"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label>Button Title</label>
                      <input v-model="section.buttonTitle" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label>Button Link</label>
                      <input v-model="section.buttonLink" class="form-control" />
                    </div>
                  </div>
                </div>

                <button @click="saveClick" class="btn btn-primary">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/homepages/state";
import { StoreActions as actions } from "@/store/admin/homepages/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";

export default {
  name: "AdminHomepagesForm",
  components: {
    MainNav,
    SyncLoader,
    AdminNav,
  },
  mounted() {
    this.dispatch(actions.getDoctors);
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      }
    });

    if (this.$route.name == routes.admin.homepagesCreate.name) {
      this.mode = "create";
      let gotDefault = (d) => {
        this.defaults = d;
      };
      this.dispatch(actions.getDefaults, gotDefault);
    } else {
      this.mode = "edit";
      let id = this.$route.params.id;
      var callback = (item) => {
        this.form = item;
      };
      this.dispatch(actions.get, { id: id, callback: callback });
    }
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      adminLinks: routes.adminLinks(),
      form: {
        title: "",
        title2: "",
        title3: "",
        desc: "",
      },
      mode: "create",
      defaults: {}
    };
  },
  computed: {
    ...mapState(StorePaths.adminHomepages, [
      state.loading,
      state.items,
      state.error,
      state.doctors
    ]),
    activeRoute() {
      return routes.admin.homepages.name;
    },
    defaultKeys() {
      return Object.keys(this.defaults);
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminHomepages}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminHomepages}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    saveClick() {
      if (this.$refs.container) {
        this.$refs.container.scrollTop = 0;
      }
      let name = this.$route.name;
      var callback = () => {
        this.$router.go(-1);
      };

      if (name == routes.admin.homepagesCreate.name) {
        this.dispatch(actions.create, { data: this.form, callback: callback });
      } else {
        this.dispatch(actions.edit, { data: this.form, callback: callback });
      }
    },
    loadDefault(key) {
      this.form = this.defaults[key];
    }
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

nav {
  margin-bottom: 0px;
}

td {
  text-align: left;
}

.curved {
  border-radius: 10px;
}

.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.section {
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
}

.section > .form-group {
  background: transparent;
}

.defaults {
  float: left;
  width: 100%;
  text-align: left;
}

.defaults > button {
  margin-right: 10px;
}
</style>