<template>
  <div>
    <main-nav :canLinks="true" />
    <div class="container-full primary">

      <!-- <div class="row mt20" v-if="userProfile">
        <div class="col-sm-6 col-sm-offset-3  secondary curved">
          <p style="padding-top: 10px; font-weight: 500">If you have multiple children/students, you can <router-link class="orange" :to="profileRoute">create student profiles</router-link> for each of them.</p>
        </div>
      </div> -->

      <div class="row">
        <div class="col-sm-12">
          <h2>CAN14</h2>
        </div>
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-12 mt20">
          <div class="row">
            <div v-if="nextUnlock && nextUnlockAfterNow" class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 countdown-container">
              <h4>Next Word Unlocks In</h4>
              <CountdownTimer
                :starttime="now" 
                :endtime="nextUnlock"
                @finished="timerFinished"
              />
            </div>
          </div>

          <div class="row" style="margin-left:20px;margin-right:20px;" v-if="profile && !profile.watchedIntro">
            <h1>Intro Video</h1>

            <button class="btn btn-primary get-started" @click="watchedIntro">Get Started</button>

          </div>
          <div class="row" style="margin-left:20px;margin-right:20px;" v-else>
            <span v-for="word in words" :key="word.word">
              <router-link
              :class="linkStyle(word)"
              :to="word.enabled && !word.completed ? `${word.link}?wordId=${word.id}`: ''">
                <WordTile :word="word" />
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import WordTile from "@/components/can/WordTile.vue";
import StorePaths from '@/store/StorePaths';
import { mapState } from 'vuex';
import { StoreState as state } from '@/store/words/state';
import { StoreActions as actions } from '@/store/words/actions'; 
import { StoreMutations as mutations } from '@/store/words/mutations'; 
import { StoreActions as GlobalActions } from '@/store/global/actions';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import CountdownTimer from '@/components/CountdownTimer.vue';
import moment from 'moment';
import ProfileRepository from '@/API/ProfileRepository';
import Profile from '@/adapters/Profile';
import MainNav from '@/components/MainNav.vue';

export default {
  name: "14Can",
  components: {
    WordTile,
    SyncLoader,
    CountdownTimer,
    MainNav
  },
  mounted() {
    if(!this.$hasSession()) {
      this.$router.push({name: routes.register.name, query: {destination: 'can'}})
      return;
    }

    this.commit(mutations.SET_LOADING, true);

    this.$store.dispatch(GlobalActions.getSessionDetails, (session) => {
      this.userProfile = !session.studentProfile;
      this.studentProfile = session.studentProfile;
    });


    this.getProfile()
    this.dispatch(actions.getWords);
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
    this.profile = null;
  },
  computed: {
    ...mapState(StorePaths.words, [
      state.loading,
      state.words,
      state.nextUnlock
    ]),
    profileRoute() {
      if(this.userProfile) {
        return {
          name: routes.myProfile.name
        } 
      } else {
        return {
          name: routes.myStudentProfile.name
        } 
      }
    },
    passportRoute() {
      return {
        name: routes.passport.name
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    now() {
      return `${moment().utc().format("DD MMM YYYY HH:mm:ss")} GMT`;
    },
    nextUnlockAfterNow() {
      let next = moment(this.nextUnlock);
      let now = moment().utc();
      return next > now;
    },
    canboardRoute() {
      return {
        name: routes.selectCanboard.name
      }
    }
  },
  data() {
    return {
      userProfile: false,
      studentProfile: false,
      profile: null
    };
  },
  methods: {
    getProfile() {
      this.commit(mutations.SET_LOADING, true);
      ProfileRepository.current().then( data => {
      this.commit(mutations.SET_LOADING, false);
        this.profile = new Profile(data.profile);
      }).catch(() => {
        this.commit(mutations.SET_LOADING, false);
      })
    },
    linkStyle(word) {
      return {
        'disabled': !word.enabled
      }
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.words}/${key}`,val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.words}/${key}`,val);
    },
    timerFinished() {
      this.refresh();
    },
    refresh() {
      this.dispatch(actions.getWords);
    },
    watchedIntro() {
      this.profile.watchedIntro = true;
      ProfileRepository.watchedIntro().then(() => {

      }).catch(() => {

      });
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.get-started {
  font-size: x-large;
}
</style>