<template>
  <!-- <ComingSoon msg="Welcome to Your Vue.js App"/> -->
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() { 
  },
  components: {
  }
}
</script>

<style>
 @import '@/assets/style.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #efefef;
  height: 100vh;
}
</style>
