import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_QUESTION: 'SET_QUESTION',
    SET_QUESTION_ID: 'SET_QUESTION_ID',
    SET_ERROR: 'SET_ERROR'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_QUESTION](state, value) {
        state[StoreState.question] = value;
    },
    [StoreMutations.SET_QUESTION_ID](state, value) {
        state[StoreState.questionId] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}