export const StoreState = {
    loading: 'loading',
    error: 'error',
    resources: 'resources'
}

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.resources]: []
}