export default class Profile {
    constructor(adapter = {
        id: 0,
        firstName: "",
        lastInitial: "",
        requiresPasscode: false,
        passcode: "",
        owner: null,
        watchedIntro: false
    }) {
        this.id = adapter.id;
        this.firstName = adapter.firstName;
        this.lastInitial = adapter.lastInitial;
        this.requiresPasscode = adapter.requiresPasscode;
        this.passcode = adapter.passcode;
        this.owner = adapter.owner;
        this.watchedIntro = adapter.watchedIntro;
    }
}