export const StoreState = {
    loading: 'loading',
    doctors: 'doctors',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.doctors]: [],
    [StoreState.error]: null
}