export default class QuestionSpeedrun {
    constructor(adapter = {
        id: 0,
        questionId: 0,
        question: "",
        placeholder: "",
        correctAnswer: ""
    }) {
        this.id = adapter.id;
        this.questionId = adapter.questionId;
        this.question = adapter.question;
        this.placeholder = adapter.placeholder;
        this.correctAnswer = adapter.correctAnswer;
    }
}