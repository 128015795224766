import Can from '@/components/can/Can';
import CanForm from '@/components/can/CanForm';
import UserLogin from '@/components/user/UserLogin'
import StudentLogin from '@/components/user/StudentLogin'
import UserRegister from '@/components/user/UserRegister'
import ForgotPassword from '@/components/user/ForgotPassword'
import ResetPassword from '@/components/user/ResetPassword'
import CompleteProfile from '@/components/user/CompleteProfile'
import MyProfile from '@/components/user/MyProfile'
import MyStudentProfile from '@/components/user/MyStudentProfile'
import BulkAddProfiles from '@/components/user/BulkAddProfiles'
import SelectProfile from '@/components/user/SelectProfile';
import Passport from '@/components/can/Passport.vue';
import SelectCanboard from '@/components/can/SelectCanboard.vue';

import Home from '@/components/Home.vue';
import Landing from '@/components/Landing.vue';
import LancasterCanLanding from '@/components/LancasterCanLanding.vue';
import PediatricianCanLanding from '@/components/PediatricianCanLanding.vue';
import OhioCanLanding from '@/components/OhioCanLanding.vue';

import AdminHome from '@/components/admin/AdminHome.vue';
import AdminSchools from '@/components/admin/AdminSchools.vue';
import AdminDoctors from '@/components/admin/AdminDoctors.vue';
import AdminAccessCodes from '@/components/admin/AdminAccessCodes.vue';
import AdminWords from '@/components/admin/AdminWords.vue';
import AdminQuestions from '@/components/admin/AdminQuestions.vue';
import AdminQuestionEdit from '@/components/admin/AdminQuestionEdit.vue';
import AdminHomepages from '@/components/admin/AdminHomepages.vue';
import AdminHomepagesForm from '@/components/admin/AdminHomepagesForm.vue';
import Healthbooks from '@/components/Healthbooks.vue';
import ReadHealthbook from '@/components/ReadHealthbook.vue';
import VerifyEmail from '@/components/user/VerifyEmail.vue';
import FoodAllergyResources from '@/components/FoodAllergyResources.vue';
import Canboard from '@/components/can/Canboard.vue';

import NotFound from '@/components/NotFound'
import RoutesList from './routes';

const routes = [
{ ...RoutesList.words, component: Can },
{ ...RoutesList.wordForm, component: CanForm },


{ ...RoutesList.login, component: UserLogin },
{ ...RoutesList.studentLogin, component: StudentLogin },
{ ...RoutesList.studentLoginWithCode, component: StudentLogin },
{ ...RoutesList.register, component: UserRegister },
{ ...RoutesList.forgotPassword, component: ForgotPassword },
{ ...RoutesList.resetPassword, component: ResetPassword },
{ ...RoutesList.completeProfile, component: CompleteProfile },
{ ...RoutesList.myProfile, component: MyProfile },
{ ...RoutesList.myStudentProfile, component: MyStudentProfile },
{ ...RoutesList.bulkAddProfiles, component: BulkAddProfiles },
{ ...RoutesList.selectProfile, component: SelectProfile },
{ ...RoutesList.home, component: Home },
{ ...RoutesList.landing, component: Landing },
{ ...RoutesList.lancasterCanlanding, component: LancasterCanLanding },
{ ...RoutesList.pediatricianCanLanding, component: PediatricianCanLanding},
{ ...RoutesList.ohioCanlanding, component: OhioCanLanding},
{ ...RoutesList.healthBooks, component: Healthbooks },
{ ...RoutesList.readHealthbook, component: ReadHealthbook},
{ ...RoutesList.verifyEmail, component: VerifyEmail },
{ ...RoutesList.passport, component: Passport },
{ ...RoutesList.foodAllergyResources, component: FoodAllergyResources},
{ ...RoutesList.canboard, component: Canboard},
{ ...RoutesList.selectCanboard, component: SelectCanboard},

// Admin
{ ...RoutesList.admin.home, component: AdminHome },
{ ...RoutesList.admin.schools, component: AdminSchools },
{ ...RoutesList.admin.doctors, component: AdminDoctors },
{ ...RoutesList.admin.words, component: AdminWords },
{ ...RoutesList.admin.accessCodes, component: AdminAccessCodes },
{ ...RoutesList.admin.questions, component: AdminQuestions },
{ ...RoutesList.admin.questionEdit, component: AdminQuestionEdit },
{ ...RoutesList.admin.questionCreate, component: AdminQuestionEdit },
{ ...RoutesList.admin.homepages, component: AdminHomepages },
{ ...RoutesList.admin.homepagesCreate, component: AdminHomepagesForm },
{ ...RoutesList.admin.homepagesEdit, component: AdminHomepagesForm },

//capture root url
{ ...RoutesList.root, component: UserLogin },
{ ...RoutesList.catchAll, component: NotFound },
{ ...RoutesList.notFound, component: NotFound },

]

export default routes;