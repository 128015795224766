<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <img :src="canLogo" width="100" class="mt20 ml10" />
          <h1>Not Found</h1>
          <h4>The page that you are looking for couldn't be found</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CanLogo from "@/assets/images/canlogo.png";
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';

export default {
  name: "ComingSoon",
  components: {
    MainNav
  },
  data() {
    return {
      canLogo: CanLogo,
      routes: routes
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-full {
  height: calc(100vh - 90px);
}

.form-control {
  border-radius: 15px;
}

.submit-btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}
</style>
