import APIConnection from '@/API/APIConnection';
export default {
    getForWord(wordId){
        return APIConnection.get(`admin/words/${wordId}/questions`);
    },
    get(id) {
        return APIConnection.get(`admin/questions/${id}`);
    },
    create(data) {
        return APIConnection.post(`admin/questions`, data);
    },
    update(id, data) {
        return APIConnection.put(`admin/questions/${id}`, data);
    }
}