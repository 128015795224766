import APIConnection from '@/API/APIConnection';
export default {
    create(data){
        return APIConnection.post('user/profile', data);
    },
    update(data){
        return APIConnection.put('user/profile', data);
    },
    getProfiles(username) {
        return APIConnection.get(`user/${username}/profiles`)
    },
    get(id) {
        return APIConnection.get(`user/profile/${id}`)
    },
    current() {
        return APIConnection.get(`user/profile`)
    },
    getBySchoolClassroom(schoolId, classroomId) {
        if(classroomId == null) {
            return APIConnection.get(`profiles/school/${schoolId}`)
        } else {
            return APIConnection.get(`profiles/school/${schoolId}/classroom/${classroomId}`)
        }
    },
    watchedIntro() {
        return APIConnection.patch('user/profile/watchedIntro', {});
    }
}