<template>
  <div>
    <main-nav :color="'black'" />
    <div class="container-full primary">

      <div class="row">
        <div class="col-sm-12">
          <h2>Admin</h2>
        </div>
      </div>

      <div class="row">
        <admin-nav :active="activeRoute" />

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
          <div class="secondary ml30 curved mr20">
            <h3 class="pt20">{{title}} Question</h3>

            <sync-loader
              :loading="loading"
              :color="`#f35b2c`"
              :size="'15px'"
              style="margin-top: 20px"
            ></sync-loader>

            <div class="row mt20" v-if="error">
              <div class="col-sm-12">
                <div class="alert" role="alert">
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row" v-if="form">
              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Title</label>
                  <textarea class="form-control" v-model="form.title" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Subtitle</label>
                  <textarea class="form-control" v-model="form.subTitle" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Question</label>
                  <textarea class="form-control" v-model="form.question" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Question Type</label>
                  <select class="form-control" v-model="form.type">
                    <option value="text">Text</option>
                    <option value="options">Options</option>
                    <option value="multi-options">Multi Options</option>
                    <option value="empty">Empty</option>
                  </select>
                </div>
              </div>


              <div class="col-xs-12 col-sm-6 col-md-4" v-if="form.type == 'text'">
                <div class="form-group">
                  <label>Placeholder</label>
                  <input class="form-control" v-model="form.placeholder" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4" v-if="form.type == 'text'">
                <div class="form-group">
                  <label>Hint Placeholder
                    <i class="fa fa-info-circle" v-tooltip="'The placeholder that appears when the user enters an incorrect answer 3 times'"/>
                  </label>
                  <input class="form-control" v-model="form.hintPlaceholder" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Sort</label>
                  <input class="form-control" v-model="form.sort" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Animation Link</label>
                  <input class="form-control" v-model="form.animation" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Animation Time (Milliseconds)</label>
                  <input class="form-control" v-model="form.animationTime" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Video Link</label>
                  <input class="form-control" v-model="form.video" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <label>Book Link</label>
                  <input class="form-control" v-model="form.book" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group">
                  <div class="toggle pointer" @click="form.complete = !form.complete">
                    <input type="checkbox" v-model="form.complete" />
                    <label class="ml10">Show Collect Badge Button</label>
                  </div>
                </div>
              </div>

              <div class="col-sm-10 col-sm-offset-1" v-if="form.type == 'options' || form.type == 'multi-options'">
                <h3>Options</h3>

                <table class="table curved">
                  <thead>
                    <tr>
                      <th>Option</th>
                      <th>Image</th>
                      <th>Correct</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="option in form.options" :key="option.id">
                      <td>
                        <textarea class="form-control" v-model="option.option" />
                      </td>
                      <td>
                        <input class="form-control" placeholder="https://" v-model="option.image" />
                      </td>
                      <td>
                        <input type="checkbox" v-model="option.correct" @click="correctChanged"/>
                        <label class="ml5">Correct Answer</label>
                      </td>
                      <td>
                        <button class="btn bt-default">
                          <i class="fa fa-remove" @click="remove(option)" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button class="btn btn-default" @click="addOption">Add Option</button>
              </div>

              <div class="col-xs-12 col-sm-4 col-sm-offset-4" v-if="form.type == 'text'">
                <h3>Correct Answer</h3>
                <div class="form-group">
                  <input placeholder="Answer" class="form-control" v-model="correctAnswer" />
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-4 col-sm-offset-4 mt20">
                <button @click="saveClick" class="btn btn-primary mb20" style="font-size:medium">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/editQuestion/state";
import { StoreMutations as mutations } from "@/store/admin/editQuestion/mutations";
import { StoreActions as actions } from "@/store/admin/editQuestion/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";
import AdminQuestion from '@/adapters/AdminQuestion';

export default {
  name: "AdminQuestionEdit",
  components: {
    MainNav,
    SyncLoader,
    AdminNav
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      } else {
        let questionId = this.$route.params.questionId;
        this.commit(mutations.SET_QUESTION_ID, questionId);
        if(questionId) {
          this.dispatch(actions.getQuestion);
          this.title = 'Edit';
        } else {
          let question = new AdminQuestion({wordId: this.$route.params.wordId});
          this.commit(mutations.SET_QUESTION, question);
        }
      }
    });
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      adminLinks: routes.adminLinks(),
      form: {},
      correctAnswer: null,
      title: 'Create'
    };
  },
  computed: {
    ...mapState(StorePaths.adminQuestionEdit, [
      state.loading, state.question, state.error
    ]),
    activeRoute() {
      return routes.admin.words.name;
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminQuestionEdit}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminQuestionEdit}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    remove(option) {
      this.form.options = this.form.options.filter(it => {
        return it.id != option.id
      });
    },
    saveClick() {
      if(this.title == 'Create') {
        this.dispatch(actions.createQuestion, this.form);
      } else if(this.title == 'Edit') {
        this.dispatch(actions.updateQuestion, this.form);
      }
    },
    addOption() {
      this.form.options.push({});
    },
    correctChanged() {
      setTimeout(() => {
        if(this.form.type == 'options' || this.form.type == 'multi-options') {
          this.form.correctAnswers = this.form.options.filter(it => {
            return it.correct
          }).map(it => {
            return it.option;
          });
        }
      }, 500);
    }
  },
  watch: {
    question(newVal) {
      if(newVal) {
        this.form = newVal;
        let answers = newVal.correctAnswers;
        if(newVal.type == 'text' && answers.length > 0) {
          this.correctAnswer = answers[0].answer;
        } else {
          this.correctAnswer = null;
        }

        let answerStrings = newVal.correctAnswers.map(it => { return it.answer });
        let options = newVal.options.map(it => {
          it.correct = answerStrings.includes(it.option);
          return it;
        });

        this.form.options = options;
      } else {
        this.form = null;
        this.correctAnswer = null;
      }
    },
    correctAnswer(newVal) {
      this.form.correctAnswers = [newVal]
    }
  }
};
</script>

<style scoped>
.toggle {
  background: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 5px;
}

.table {
  background: white;
}

.btn-primary {
  border-radius: 10px;
}
</style>