const defaultAxios = require('axios').default;
import app from '@/main';

const APIConnection = {
    session() {
        return app.config.globalProperties.$getSession();
    },
    url:'https://api.loc.ample.software/public',
    fullUrl(path) {
        return `${this.url}/${path}`
    },
    get(url,interceptor = this.mainInterceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: this.session()
            }
        };

        return instance.get(this.fullUrl(url), config);
    },
    post(url, params, interceptor = this.mainInterceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: this.session()
            }
        };

        return instance.post(this.fullUrl(url), params, config);
    },
    put(url, params, interceptor = this.mainInterceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: this.session()
            }
        };

        return instance.put(this.fullUrl(url), params, config);
    },
    patch(url, params, interceptor = this.mainInterceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: this.session()
            }
        };

        return instance.patch(this.fullUrl(url), params, config);
    },
    delete(url, params, interceptor = this.mainInterceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: this.session()
            },
            data: params
        };

        return instance.delete(this.fullUrl(url), config);
    },
    mainInterceptor: (axios) => {
        axios.interceptors.response.use(function (response) {
            let data = response.data;
            if(data.error) {
                return Promise.reject(data.error);
            }
            return data;
        }, function (error) {
            return Promise.reject(error);
        });
    }
};

export default APIConnection;
