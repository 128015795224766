import { StoreMutations } from "./mutations";
import CanboardRepository from "@/API/CanboardRepository";
import Canboard from "@/adapters/Canboard";
import { StoreState } from "./state";
import WordRepository from "@/API/WordRepository";
import Word from "@/adapters/Word";

export const StoreActions = {
    getBoard: 'getBoard',
    create: 'create',
    getWord: 'getWord',
    getCanboardWords: 'getCanboardWords',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getBoard] ({ commit, state }) {
        commit(StoreMutations.SET_LOADING, true);

        let wordId = state[StoreState.wordId];
        CanboardRepository.get(wordId).then( res => {
            let board = res.items.map(it => {
                return new Canboard(it);
            })
            commit(StoreMutations.SET_BOARD, board);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.create] ({ commit, state }, seconds) {
        commit(StoreMutations.SET_LOADING, true);

        let wordId = state[StoreState.wordId];

        CanboardRepository.create(wordId, seconds).then( res => {
            let place = res.leaderboardItem;
            commit(StoreMutations.SET_PLACE, place);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getWord] ({commit, state}) {
        let wordId = state[StoreState.wordId];

        WordRepository.get(wordId).then(data => {
            let word = new Word(data.word);
            commit(StoreMutations.SET_WORD, word);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.getCanboardWords] ({commit}) {
        WordRepository.canboardWords().then(data => {
            let words = data.words.map(it => {
                return new Word(it);
            });
            commit(StoreMutations.SET_WORDS, words);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_BOARD, []);
        commit(StoreMutations.SET_PLACE, null);
        commit(StoreMutations.SET_WORDID, null);
        commit(StoreMutations.SET_WORD, null);
        commit(StoreMutations.SET_WORDS, []);
    }
}