<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Previous Questions"
      @hide="callback"
      :footer="false"
    >

    <div v-for="question in questions" :key="question.id" class="secondary curved" style="padding:10px; margin-top:10px;">
        <h4>{{question.title}}</h4>
        <h6>{{question.subTitle}}</h6>
        <p>{{question.question}}</p>
    </div>

  </modal>
</template>

<script>
export default {
    name: "CanFormQuestions",
    props: {
        showing: {
            default: false
        },
        questions: {
            default: []
        }
    },
    data(){ 
        return {
            open: false
        }
    },
    methods: {
        callback() {
            this.$emit("closed")
        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style>

</style>