<template>
<div>
  <main-nav :color="'black'" />
  <div class="container-full primary">
    <div class="row">
      <div class="col-sm-12">
        <h2>Admin</h2>
      </div>
    </div>
    <div class="row">
      <admin-nav :active="activeRoute"/>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
        <div class="secondary ml30 curved mr20">
          <h3>Homepages</h3>

          <sync-loader
            :loading="loading"
            :color="`#f35b2c`"
            :size="'15px'"
            style="margin-top: 20px"
          ></sync-loader>

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <router-link :to="createRoute">
            <button class="btn btn-default curved">
              <i class="fa fa-plus" /> Create
            </button>
          </router-link>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Slug</th>
                <th>Title</th>
                <th>Title2</th>
                <th>Title3</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{item.slug}}</td>
                <td>{{item.title}}</td>
                <td>{{item.title2}}</td>
                <td>{{item.title3}}</td>
                <td>
                  <router-link :to="editLink(item.id)">
                    <button class="btn btn-default">
                      <i class="fa fa-pencil" />
                    </button>
                  </router-link>
                  <button class="btn btn-danger ml10" @click="pendingDelete = item.slug">
                    <i class="fa fa-trash" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <delete-confirmation
      :showing="showingDeleteConf"
      :name="pendingDelete"
      @closed="closedDelete"
      @delete="deleteHomepage"
    />
  </div>
</div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/homepages/state";
import { StoreActions as actions } from "@/store/admin/homepages/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import MainNav from "@/components/MainNav.vue";

export default {
  name: "AdminHomepages",
  components: {
    SyncLoader,
    AdminNav,
    DeleteConfirmation,
    MainNav
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      }
    });

    this.dispatch(actions.getAll);
  },
  unmounted() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      adminLinks: routes.adminLinks(),
      pendingDelete: null
    };
  },
  computed: {
    ...mapState(StorePaths.adminHomepages, [state.loading, state.items, state.error]),
    activeRoute() {
      return routes.admin.homepages.name;
    },
    createRoute() {
      return {name: routes.admin.homepagesCreate.name}
    },
    showingDeleteConf() {
      return this.pendingDelete != null
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminHomepages}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminHomepages}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    editLink(id) {
      return {name: routes.admin.homepagesEdit.name, params: {id: id}}
    },
    closedDelete() {
      this.pendingDelete = null
    },
    deleteHomepage() {
      let slug = this.pendingDelete;
      let page = this.items.find(it => {
        return it.slug == slug;
      })

      if(page) {
        this.dispatch(actions.delete, page.id);
      }

      this.pendingDelete = null;
    }
  },
  watch: {
    error(newVal) {
      if(newVal) {
        this.$notify({
          type: 'danger',
          title: newVal
        })
      }
    }
  }
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

nav {
  margin-bottom: 0px;
}

td {
  text-align: left;
}

.curved {
  border-radius: 10px;
}

.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>