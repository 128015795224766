export default class School {
    constructor(adapter = {
        id: 0,
        name: "",
        schoolId: 0
    }) {
        this.schoolId = adapter.schoolId;
        this.name = adapter.name;
        this.id = adapter.id;
    }
}