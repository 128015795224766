<template>
  <div>
      <div class="day" v-if="days > 0">
        <span class="number">{{ days }}</span>
        <div class="format">Days</div>
      </div>
      <div class="hour">
        <span class="number">{{ hours }}</span>
        <div class="format">Hours</div>
      </div>
      <div class="min">
        <span class="number">{{ minutes }}</span>
        <div class="format">Minutes</div>
      </div>
      <div class="sec">
        <span class="number">{{ seconds }}</span>
        <div class="format">Seconds</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  props: ["starttime", "endtime"],
  data: function () {
    return {
      timer: "",
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: ""
    };
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);

      if(this.days == 0 && this.hours == 0 && this.minutes == 0 && this.seconds == 0) {
        this.$emit("finished");
      }
    },
  },
};
</script>

<style scoped>

.timer {
  font-size: 20px;
  color: #fff;
  text-align:center;
  margin-top: 50px;
}

.day, .hour, .min, .sec {
    font-size: 30px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    margin: 10px;
}

.number{
    background: white;
    padding: 0 5px;
    border-radius: 5px;
    display: inline-block;
    width: 60px;
    text-align: center;
}

.format {
  font-size:medium;
  margin-top:10px;
}

.message {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
}

.status-tag{
    width: 270px;
    margin: 10px auto;
    padding: 8px 0;
    font-weight: 500;
    color: #000;
    text-align: center;
    border-radius: 15px;
}
</style>