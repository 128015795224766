import APIConnection from '@/API/APIConnection';
export default {
    getAll(){
        return APIConnection.get('admin/accessCodes');
    },
    search(token){
        return APIConnection.get(`admin/accessCodes/search/${token}`);
    },
    get(id) {
        return APIConnection.get(`admin/accessCodes/${id}`);
    },
    create(data) {
        return APIConnection.post('admin/accessCodes', data);
    },
    update(id, data) {
        return APIConnection.put(`admin/accessCodes/${id}`, data);
    },
    delete(id) {
        return APIConnection.delete(`admin/accessCodes/${id}`);
    }
}