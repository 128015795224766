<template>
  <div class="">
    <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
            <div class="row mt20" v-if="error">
            <div class="col-sm-12">
                <div class="alert" role="alert">
                {{ error }}
                </div>
            </div>
            </div>
        </div>
        <div class="col-sm-6 col-sm-offset-3 pt20" v-if="user">
            <i class="fa fa-bullhorn" />
            <p>You entered <strong>{{user.email}}</strong> as the email address for your account.</p>
            <p>Please verify this email address by clicking the link we sent you. If you have not received an email, check your spam folder or click "resend email" below.</p>
        </div>
    </div>

    <button class="btn btn-primary" @click="resendEmail">Resend Email</button>
    <button class="btn btn-default ml10" @click="editingEmail = true">Change Email</button>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 mt20">
        <p class="pointer orange" @click="checkAgain"><i class="fa fa-refresh" /></p>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 mt20">
        <p v-if="didResend">
          Email Sent! If you still do not receive an email, don't forget to check your spam folder
        </p> 
      </div>
    </div>

    <div class="row mt20" v-if="editingEmail">
        <div class="col-sm-4 col-sm-offset-4 secondary change-email">
            <h4>Change Email</h4>
            <input class="form-control mb20" v-model="email"/>
            <button @click="editingEmail = false" class="btn btn-default mr10">Cancel</button>
            <button class="btn btn-primary" @click="saveEmail">Save</button>
        </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <sync-loader
          :loading="loading"
          :color="`#f35b2c`"
          :size="'15px'"
          style="margin-top: 20px"
        ></sync-loader>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/myProfile/state";
import { StoreMutations as mutations } from "@/store/myProfile/mutations";
import { StoreActions as actions } from "@/store/myProfile/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

export default {
  name: "PendingVerifyEmail",
  components: {
    SyncLoader
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
        editingEmail: false,
        email: "",
        user: null,
        didResend: false
    }
  },
  computed: {
        ...mapState(StorePaths.myProfile, [state.loading, state.error]),
  },
  methods: {
    fetch() {
        this.commit(mutations.SET_LOADING, true);
        this.$store.dispatch(GlobalActions.getUser, (user) => {
        this.commit(mutations.SET_LOADING, false);
        if(user) {
            this.email = user.email;
            this.user = user;

            if(user.emailConfirmed) {
              this.$hidePendingEmailModal();
            }
        }
        });
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.myProfile}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.myProfile}/${key}`, val);
    },
    saveEmail() {
        var callback = () => {
            this.$notify({
                type: 'success',
                message: 'Email updated! Please verify your email address by clicking the link we sent you.'
            });

        }
        this.dispatch(actions.updateEmail,{email: this.email, callback: callback});
    },
    resendEmail() {
      this.dispatch(actions.resendVerifyEmail, () => {
        this.didResend = true;
      });
    },
    checkAgain() {
      this.fetch()
    }
  }
};
</script>

<style scoped>
.btn {
    margin-top: 10px;
    border-radius: 15px;
    font-size: large;
}

p {
    font-size: medium;
}

.fa-bullhorn {
    font-size: 40pt;
    margin-bottom:20px;
    color: #f35b2c;
    background: #efefef;
    padding:15px;
    border-radius: 50px;
}

.change-email {
    border-radius: 20px;
    padding-bottom: 25px;
    padding-top: 10px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>