export default class HomeSection {
    constructor(adapter = {
        id: 0,
        title: "",
        desc: "",
        image: null,
        buttonTitle: null,
        buttonLink: null
    }) {
        this.id = adapter.id;
        this.title = adapter.title;
        this.desc = adapter.desc;
        this.image = adapter.image;
        this.buttonTitle = adapter.buttonTitle;
        this.buttonLink = adapter.buttonLink;
    }
}