<template>
  <div class="container-full primary">
    <div class="row">
      <div class="col-sm-12">
        <h2>Select a Profile</h2>
      </div>

      <div class="col-sm-12">
        <br /><br />
        <sync-loader
          :loading="loading"
          :color="`#f35b2c`"
          :size="'15px'"
          style="margin-top: 20px"
        ></sync-loader>

        <div class="row mt20" v-if="error">
          <div class="col-sm-12">
            <div class="alert" role="alert">
              {{ error }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6" v-if="user">
        <div class="ml10 mr10 primary curved card" @click="clickedUser()">
          <p class="card-name" v-tooltip="'Account Owner'">
            {{ user.firstName }} {{ user.lastName }}
            <i class="fa fa-star" />
          </p>
        </div>
      </div>

      <div v-if="user">
        <div
          class="col-xs-12 col-sm-6"
          v-for="profile in user.profiles"
          :key="profile.id"
        >
          <div
            class="ml10 mr10 primary curved card"
            @click="clickedProfile(profile)"
          >
            <p class="card-name">
              {{ profile.firstName }} {{ profile.lastInitial }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from "@/API/UserRepository";
import User from "@/adapters/User";
import routes from "@/routes";
export default {
  name: "SelectProfile",
  mounted() {
    this.loading = true;
    UserRepository.get()
      .then((data) => {
        let user = new User(data.user);
        this.user = user;
      })
      .catch((e) => {
        this.error = e;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      user: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    clickedUser() {
      this.error = null;
      this.loading = true;

      UserRepository.clearSessionProfile()
        .then(() => {
          this.$router.push({ name: routes.home.name });
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickedProfile(profile) {
      this.error = null;
      this.loading = true;
      UserRepository.updateSessionProfile(profile.id)
        .then(() => {
          this.$router.push({ name: routes.home.name });
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: x-large;
  overflow: scroll;
  background: #efefef;
}

.card-name {
  padding-left: 10px;
  padding-right: 10px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
  margin:10px;
}
</style>