<template>
  <Modal v-model="show" :title="title" size="lg">
    <div v-if="item">
         <div>
            <p style="text-align: left; font-weight:600;">Date</p>
            <p style="text-align: left;">{{item.ts}}</p>
        </div>

        <div>
            <p style="text-align: left; font-weight:600;">Duration</p>
            <p style="text-align: left;">{{duration}}</p>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answers</th>
                            <th>Attempts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="question in questions" :key="question.id">
                            <td>
                                {{question.title}}
                                {{question.subTitle}}
                                {{question.question}}
                            </td>
                            <td>
                                <span v-for="answer in question.answers" :key="`answer_${answer.id}`">
                                    <p class="answer">
                                        <i class="fa fa-star" v-tooltip="'Correct Answer'"  v-if="answer.correct == 1"/>
                                        <i class="fa fa-close" v-tooltip="'Correct Answer'"  v-if="answer.correct == 0"/><br>
                                        {{answer.answer}}
                                    </p>
                                </span>
                            </td>
                            <td>{{question.answers.length}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <template #footer>
      <div>
        <Btn type="primary" @click="show = false" :disabled="loading">Done</Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import WordRepository from '@/API/WordRepository';
import CompletedWordInfo from '@/adapters/CompletedWordInfo';
export default {
  name: "CompletedWordInfo",
  props: {
    open: {
      default: false,
    },
    item: {
      required: true,
    },
  },
  mounted() {
    this.show = this.open;
  },
  computed: {
    title() {
        if(!this.item) { return '' }
        if(this.item.profileName) {
            return `${this.item.wordName} - ${this.item.profileName}`;
        } else if(this.item.userName) {
            return `${this.item.wordName} - ${this.item.userName}`;
        }

        return this.item.wordName;
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      error: null,
      questions: [],
      duration : ""
    };
  },
  methods: {
    load() {
        this.loading = true;
        this.error = null;
        WordRepository.getCompletedInfo(this.item.id).then(data => {
            let info = new CompletedWordInfo(data.info);
            this.questions = info.questions.filter(it => {
                return it.type == "text" || it.type == "options" || it.type == "multi-options";
            });
            this.duration = info.duration;
        }).catch(err => {
            this.error = err;
        }).finally(() => {
            this.loading = false;
        });
    }
  },
  watch: {
    show(newVal) {
      this.$emit("changed", newVal);
    },
    open(newVal) {
      this.show = newVal;
    },
    item(newVal) {
        if(newVal) {
            this.load();
        }
    }
  }
};
</script>

<style scoped>
.answer {
    background: #efefef;
    border-radius: 10px;
    padding: 10px;
}
</style>