<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Verify Email"
      @hide="callback"
      :footer="false"
    >
    <pending-verify-email />
  </modal>
</template>

<script>
import PendingVerifyEmail from '@/components/user/PendingVerifyEmail.vue';
import { StoreMutations } from '@/store/global/mutations';
export default {
    name: "PendingVerifyEmailModal",
    components: {
        PendingVerifyEmail
    },
    props: {
        showing: {
            default: false
        }
    },
    data(){ 
        return {
            open: false
        }
    },
    methods: {
        callback() {
            this.$emit("closed")
            this.$store.commit(StoreMutations.SET_SHOW_VERIFY_EMAIL, false);
        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style>

</style>