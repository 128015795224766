export const StoreState = {
    loading: 'loading',
    words: 'words',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.words]: [],
    [StoreState.error]: null
}