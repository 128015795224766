<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Delete"
      @hide="callback"
      :footer="false"
    >
    <div  style="padding:10px; margin-top:10px;">
        <h4>Are you sure you want to delete {{name}}? <br>This action can't be undone.</h4>
        <button @click="deleteClick" class="btn btn-danger">Delete</button>
        <button @click="close" class="btn btn-default">Cancel</button>
    </div>
  </modal>
</template>

<script>
export default {
    name: "DeleteConfirmation",
    props: {
        showing: {
            default: false
        },
        name: {
            default: "this"
        }
    },
    mounted() {
        this.open = this.showing
    },
    data(){ 
        return {
            open: false,
            confirmName: ""
        }
    },
    methods: {
        callback() {
            this.$emit("closed")
        },
        deleteClick() {
            this.$emit("delete")
        },
        close() {
            this.open = false;
            this.callback();

        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style scoped>
.btn {
    border-radius: 10px;
    width: 100%;
    margin: 10px;
}
</style>