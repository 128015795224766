export const StoreState = {
    loading: 'loading',
    question: 'question',
    questionId: 'questionId',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.question]: null,
    [StoreState.questionId]: null,
    [StoreState.error]: null
}