<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row mt20">
        <div class="col-sm-12">
          <h2>Forgot Password</h2>
        </div>
        <div
          class="col-xs-12 col-sm-8 col-md-4 col-sm-offset-2 col-md-offset-4"
        >
          <img
            :src="canLogo"
            width="100"
            class="mt20 ml10"
          />
          <br /><br />
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <p>Enter your email address below to receive a password reset link.</p>

          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="email"
              v-model="email"
              placeholder="me@example.com"
              :disabled="loading"
              v-on:keyup.enter="sendClick"
            />
          </div>

          <button
            :disabled="loading"
            @click="sendClick"
            class="btn btn-primary btn-login"
          >
            Send Email
          </button>
          
          
          <router-link :to="loginRoute">
            <button class="btn btn-default btn-register">Login</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/login/state";
import { StoreActions as actions } from "@/store/login/actions";
import { StoreMutations as mutations } from "@/store/login/mutations";
import MainNav from "@/components/MainNav.vue";
import CanLogo from '@/assets/images/canlogo.png';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
export default {
  name: "UserLogin",
  components: {
    MainNav,
    SyncLoader
  },
  mounted() {
    if (this.$hasSession()) {
      this.$router.push({name: routes.home.name });
    }

    let destination = this.$route.query.destination;
    this.commit(mutations.SET_DESTINATION, destination);
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      email: "",
      password: "",
      canLogo: CanLogo
    };
  },
  computed: {
    ...mapState(StorePaths.login, [state.loading, state.error, state.destination]),
    loginRoute() {
      return {
        name: routes.login.name,
        query: {
          email: this.email,
          destination: this.destination
        },
      };
    },
    studentRoute() {
      return {
        name: routes.studentLogin.name
      };
    },
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.login}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.login}/${key}`, val);
    },
    sendClick() {
      this.commit(mutations.SET_ERROR, null);
      this.dispatch(actions.forgotPassword, {
        email: this.email
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  background: #efefef;
  border-radius: 15px;
  padding: 10px;
}

input {
  border-radius: 10px !important;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.btn-login {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
}

.btn-register {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
  margin-top: 20px;
}

.btn-forgot {
  margin-top: 20px;
}

.logo {
  height: 50px;
  margin-top: 10px;
}

.container-full {
  padding: 0px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>