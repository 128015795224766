import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_PLACE: 'SET_PLACE',
    SET_BOARD: 'SET_BOARD',
    SET_WORDID: 'SET_WORDID',
    SET_WORD: 'SET_WORD',
    SET_WORDS: 'SET_WORDS'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_PLACE](state, value) {
        state[StoreState.place] = value;
    },
    [StoreMutations.SET_BOARD](state, value) {
        state[StoreState.board] = value;
    },
    [StoreMutations.SET_WORDID](state, value) {
        state[StoreState.wordId] = value;
    },
    [StoreMutations.SET_WORD](state, value) {
        state[StoreState.word] = value;
    },
    [StoreMutations.SET_WORDS](state, value) {
        state[StoreState.words] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}