export default class Canboard {
    constructor(adapter = {
        name: "",
        place: null,
        userId: null,
        profileId: null,
        isMe: false,
        seconds: 0
    }) {
        this.name = adapter.name;
        this.place = adapter.place;
        this.userId = adapter.userId;
        this.profileId = adapter.profileId;
        this.isMe = adapter.isMe;
        this.seconds = adapter.seconds.toFixed(2);
    }
}