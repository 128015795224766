import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_BOOKS: 'SET_BOOKS',
    SET_BOOK_ID: 'SET_BOOK_ID',
    SET_BOOK: 'SET_BOOK'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_BOOKS](state, value) {
        state[StoreState.books] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    },
    [StoreMutations.SET_BOOK_ID](state, value) {
        state[StoreState.bookId] = value;
    },
    [StoreMutations.SET_BOOK](state, value) {
        state[StoreState.book] = value;
    }
}