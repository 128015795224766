import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_HOME: 'SET_HOME',
    SET_PASSPORT: 'SET_PASSPORT'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_HOME](state, value) {
        state[StoreState.home] = value;
    },
    [StoreMutations.SET_PASSPORT](state, value) {
        state[StoreState.passport] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}