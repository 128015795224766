export default {
  randomString(length) {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
    },
    compareArrays(first, second){
        //write type error
        return first.every((e)=> second.includes(e)) && second.every((e)=> first.includes(e));
    },
    getTextColor(backgroundColor) {
        let brightness = this.getBrightness(backgroundColor);
        if(brightness === 0) {
          return "white";
        }
        return (this.getBrightness(backgroundColor) > 125) ? 'black' : 'white';
      },
      isBright(color) {
        return this.getBrightness(color) > 125;        
      },
      getBrightness(color) {
        let rgb = this.hexToRgb(color);
        if(!rgb) {
          return 0;
        }
    
        // http://www.w3.org/TR/AERT#color-contrast
        return Math.round(((parseInt(rgb.r) * 299) +
                            (parseInt(rgb.b) * 587) +
                            (parseInt(rgb.g) * 114)) / 1000);
      },
      hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      },

      states() {
        return [
          { label: 'Alabama', value: 'AL'},
          { label: 'Alaska', value: 'AK'},
          { label: 'Arizona', value: 'AZ'},
          { label: 'Arkansas', value: 'AR'},
          { label: 'California', value: 'CA'},
          { label: 'Colorado', value: 'CO'},
          { label: 'Connecticut', value: 'CT'},
          { label: 'Delaware', value: 'DE'},
          { label: 'District of Columbia', value: 'DC'},
          { label: 'Florida', value: 'FL'},
          { label: 'Georgia', value: 'GA'},
          { label: 'Hawaii', value: 'HI'},
          { label: 'Idaho', value: 'ID'},
          { label: 'Illinois', value: 'IL'},
          { label: 'Indiana', value: 'IN'},
          { label: 'Iowa', value: 'IA'},
          { label: 'Kansas', value: 'KS'},
          { label: 'Kentucky', value: 'KY'},
          { label: 'Louisiana', value: 'LA'},
          { label: 'Maine', value: 'ME'},
          { label: 'Maryland', value: 'MD'},
          { label: 'Massachusetts', value: 'MA'},
          { label: 'Michigan', value: 'MI'},
          { label: 'Minesota', value: 'MN'},
          { label: 'Mississippi', value: 'MS'},
          { label: 'Missouri', value: 'MO'},
          { label: 'Montana', value: 'MT'},
          { label: 'Nebraska', value: 'NE'},
          { label: 'Nevada', value: 'NV'},
          { label: 'New Hampshire', value: 'NH'},
          { label: 'New Jersey', value: 'NJ'},
          { label: 'New Mexico', value: 'NM'},
          { label: 'New York', value: 'NY'},
          { label: 'North Carolina', value: 'NC'},
          { label: 'North Dakota', value: 'ND'},
          { label: 'Ohio', value: 'OH'},
          { label: 'Oklahoma', value: 'OK'},
          { label: 'Oregon', value: 'OR'},
          { label: 'Pennsylvania', value: 'PA'},
          { label: 'Rhode Island', value: 'RI'},
          { label: 'South Carolina', value: 'SC'},
          { label: 'South Dakota', value: 'SD'},
          { label: 'Tennessee', value: 'TN'},
          { label: 'Texas', value: 'TX'},
          { label: 'Utah', value: 'UT'},
          { label: 'Vermont', value: 'VT'},
          { label: 'Virginia', value: 'VA'},
          { label: 'Washington', value: 'WA'},
          { label: 'West Virginia', value: 'WV'},
          { label: 'wisconsin', value: 'WI'},
          { label: 'Wyoming', value: 'WY' }
        ];
      },
    numberSuffix(i) {
      var j = i % 10,
          k = i % 100;
      if (j == 1 && k != 11) {
          return i + "st";
      }
      if (j == 2 && k != 12) {
          return i + "nd";
      }
      if (j == 3 && k != 13) {
          return i + "rd";
      }
      return i + "th";
  }
}