import AdminQuestionOption from "./AdminQuestionOption";
import AdminQuestionAnswer from "./AdminQuestionAnswer";

export default class AdminQuestion {
    constructor(adapter = {
        id: 0,
        title: "",
        subTitle : "",
        question: "",
        type: "",
        options: [],
        placeholder: "",
        hintPlaceholder:"",
        video:null,
        book: null,
        complete: false,
        animation: null,
        animationTime: null,
        sort: 0,
        correctAnswers: [],
        wordId: null
    }) {
        this.id = adapter.id;
        this.title = adapter.title;
        this.subTitle = adapter.subTitle;
        this.question = adapter.question;
        this.type = adapter.type;
        this.options = adapter.options?.map(it => {
            return new AdminQuestionOption(it);
        }) || [];
        this.correctAnswers = adapter.correctAnswers?.map(it => {
            return new AdminQuestionAnswer(it);
        }) || [];
        this.placeholder = adapter.placeholder;
        this.hintPlaceholder = adapter.hintPlaceholder;
        this.video = adapter.video;
        this.book = adapter.book;
        this.complete = adapter.complete;
        this.animation = adapter.animation;
        this.animationTime = adapter.animationTime;
        this.sort = adapter.sort;
        this.wordId = adapter.wordId;
    }
}