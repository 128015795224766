import QuestionSpeedrun from "./QuestionSpeedrun";

export default class Question {
    constructor(adapter = {
        id: 0,
        title: "",
        subTitle : "",
        question: "",
        type: "",
        options: [],
        placeholder: "",
        hintPlaceholder:"",
        correctAnswers: [],
        video:null,
        book: null,
        complete: false,
        animation: null,
        animationTime: null
    }) {
        this.id = adapter.id;
        this.title = adapter.title;
        this.subTitle = adapter.subTitle;
        this.question = adapter.question;
        this.type = adapter.type;
        this.options = adapter.options;
        this.placeholder = adapter.placeholder;
        this.hintPlaceholder = adapter.hintPlaceholder;
        this.correctAnswers = adapter.correctAnswers.map(it => {
            return it.answer;
        });
        this.video = adapter.video;
        this.book = adapter.book;
        this.complete = adapter.complete;
        this.animation = adapter.animation;
        this.animationTime = adapter.animationTime;

        this.speedruns = [];
        if(adapter.speedruns) {
            this.speedruns = adapter.speedruns.map(it => {
                return new QuestionSpeedrun(it);
            });
        }
    }
}