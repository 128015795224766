export const StoreState = {
    loading: 'loading',
    schools: 'schools',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.schools]: [],
    [StoreState.error]: null
}