import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_SHOW_VERIFY_EMAIL: 'SET_SHOW_VERIFY_EMAIL'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_SHOW_VERIFY_EMAIL](state, value) {
        state[StoreState.showVerifyEmail] = value;
    }
}