import School from "./School";

export default class SchoolSection {
    constructor(adapter = {
        state: "",
        schools: []
    }) {
        this.state = adapter.state;
        this.schools = adapter.schools.map(it => {
            return new School(it);
        })
    }
}