export default class {
    constructor(adapter = {
        id: 0,
        questionId: 0,
        answer: ""
    }) {
        this.id = adapter.id;
        this.questionId = adapter.questionId;
        this.answer = adapter.answer;
    }
}