import APIConnection from '@/API/APIConnection';
export default {
    getAll(){
        return APIConnection.get('admin/doctors');
    },
    get(id) {
        return APIConnection.get(`admin/doctors/${id}`);
    },
    create(data) {
        return APIConnection.post('admin/doctors', data);
    },
    update(id, data) {
        return APIConnection.put(`admin/doctors/${id}`, data);
    }
}