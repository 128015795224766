import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_PROFILES: 'SET_PROFILES',
    SET_SCHOOLS: 'SET_SCHOOLS',
    SET_DESTINATION: 'SET_DESTINATION'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_PROFILES](state, value) {
        state[StoreState.profiles] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    },
    [StoreMutations.SET_SCHOOLS](state, value) {
        state[StoreState.schools] = value;
    },
    [StoreMutations.SET_DESTINATION](state, value) {
        state[StoreState.destination] = value;
    }
}