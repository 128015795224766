<template>
  <Modal v-model="show" :title="`${this.id ? 'Edit' : 'Add'} School`">
    <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px; margin-bottom: 20px"
    ></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="form-group" v-if="!loading">
      <label>Name</label>
      <input
        class="form-control"
        placeholder=""
        v-model="name"
        @keyup.enter="saveClick"
      />
    </div>
    <div class="form-group" v-if="!loading">
      <label>State</label>
      <select class="form-control" v-model="state">
        <option v-for="state in states" :key="state.value" :value="state.value">{{state.label}}</option>
      </select>
    </div>
    <template #footer>
      <div>
        <Btn @click="show = false">Cancel</Btn>
        <Btn type="primary" @click="saveClick" :disabled="loading">Save</Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import AdminSchoolRepository from '@/API/AdminSchoolRepository';
import School from '@/adapters/School';
import Utils from '@/utils/Utils';

export default {
  name: "AdminSchoolFormModal",
  mounted() {
    this.show = this.open;
  },
  components: {
    SyncLoader,
  },
  computed: {
    states() {
        return Utils.states();
    }
  },
  props: {
    open: {
      default: false,
    },
    id: {
      default: null,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: null,
      name: "",
      state: ""
    };
  },
  methods: {
    saveClick() {
      let data = {
        name: this.name,
        state: this.state
      };
      this.loading = true;
      this.error = null;
      if (this.id) {
        AdminSchoolRepository.update(this.id, data)
          .then(() => {
            this.show = false;
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        AdminSchoolRepository.create(data)
          .then(() => {
            this.show = false;
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getSchool() {
      this.loading = true;
      AdminSchoolRepository.get(this.id)
        .then((data) => {
          let school = new School(data.school);
          this.name = school.name;
          this.state = school.state;
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    show(newVal) {
      this.$emit("changed", newVal);
    },
    open(newVal) {
      this.show = newVal;
      if (newVal) {
        this.name = "";
        this.state = "";
        this.error = null;

        if (this.id) {
          this.getSchool();
        }
      }
    },
  },
};
</script>

<style>
.modal-content {
  border-radius: 15px;
}
</style>

<style scoped>
.alert {
  color: white !important;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>