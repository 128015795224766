import APIConnection from '@/API/APIConnection';
export default {
    getAll(){
        return APIConnection.get('words');
    },
    get(id){
        return APIConnection.get(`words/${id}`);
    },
    complete(id) {
        return APIConnection.post(`words/${id}/completed`, {});
    },
    recentlyCompleted() {
        return APIConnection.get(`words/completed/recently`);
    },
    getCompletedInfo(id) {
        return APIConnection.get(`words/completed/${id}/info`);
    },
    canboardWords() {
        return APIConnection.get('canboardWords');
    }
}