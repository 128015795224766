import WordRepository from "@/API/WordRepository";
import { StoreMutations } from "./mutations";
import Word from '@/adapters/Word';

export const StoreActions = {
    getWords: 'getWords',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getWords] ({ commit }) {
        commit(StoreMutations.SET_LOADING, true);

        WordRepository.getAll().then((data) => {
            let words = data.words.map(it => {
                return new Word(it);
            });
            let nextUnlock = data.nextUnlock;

            commit(StoreMutations.SET_NEXT_UNLOCK, nextUnlock);
            commit(StoreMutations.SET_WORDS, words);
        }).catch(() => {
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_WORDS, []);
        commit(StoreMutations.SET_NEXT_UNLOCK, null);
    }
}