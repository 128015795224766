<template>
<div>
  <main-nav />

  <div class="container-full primary">
    <sync-loader
      :loading="loading"
      :color="'#f35b2c'"
      :size="'15px'"
      style="padding-right: 30px; margin-top: 20px"
    ></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-4 col-sm-offset-4">
        <div class="alert" :style="backgroundStyle" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 secondary curved mt20">

        <h2 v-if="word">{{word.name}}</h2>

        <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in board" :key="item.place" style="text-align: left;">
            <td>
              <strong>
                {{suffix(item.place)}}
              </strong>
            </td>
            <td>{{item.name}}</td>
            <td>{{item.seconds}} s</td>
            <td>
              <i v-if="item.isMe" class="fa fa-star orange" v-tooltip="'This is you!'"/>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { mapState } from "vuex";
import { StoreState as state } from "@/store/canboard/state";
import { StoreActions as actions } from "@/store/canboard/actions";
import { StoreMutations as mutations } from "@/store/canboard/mutations";
import StorePaths from "@/store/StorePaths";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import MainNav from '@/components/MainNav.vue';
import routes from '@/routes'
import { StoreActions as GlobalActions } from '@/store/global/actions';
import Utils from '@/utils/Utils';

export default {
  name: "CanboardTable",
  components: {
    SyncLoader,
    MainNav
  },
  data() {
    return {
      userProfile: false
    }
  },
  mounted() {

    let wordId = this.$route.params.wordId;
    this.commit(mutations.SET_WORDID, wordId);
    this.dispatch(actions.getBoard);
    this.dispatch(actions.getWord);

    if(!wordId) {
      this.$router.push({name: routes.selectCanboard.name});
    }

    this.$store.dispatch(GlobalActions.getSessionDetails, (session) => {
      this.userProfile = !session.studentProfile;
    });
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  computed: {
    ...mapState(StorePaths.canboard, [state.loading, state.error, state.board, state.word]),
    profileRoute() {
      if(this.userProfile) {
        return {
          name: routes.myProfile.name
        } 
      } else {
        return {
          name: routes.myStudentProfile.name
        } 
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.canboard}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.canboard}/${key}`, val);
    },
    suffix(place) {
      return Utils.numberSuffix(place);
    }
  },
};
</script>

<style scoped>
input {
  font-size: large;
}

.btn {
  font-size: large;
  border-radius: 10px;
  margin-top: 20px;
}

.btn-start {
  font-size: x-large;
  border-radius: 10px;
  margin-top: 20px;
}
</style>