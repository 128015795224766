<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
    <div class="secondary ml30 curved mr20">
      <div :class="selected(link.name)" class="primary curved p10" v-for="link in adminLinks" :key="link.name">
        <router-link :to="route(link)" class="pointer black">
          <p>{{link.title}}</p>
        </router-link>
      </div>
      <div class="primary curved p10 loc-bg">
        <p @click="logout" class="white pointer">Logout</p>
      </div>
    </div>
  </div>
</template>

<script>
import routes from '@/routes';
export default {
    name: 'AdminNav',
    props: {
        active: {
            default: "adminHome"
        }
    },
    methods: {
        logout() {
            this.$logout();
        },
        selected(name) {
            return {
                selected: this.active == name
            }
        },
        route(link) {
          return {
            name: link.name
          }
        }
    },
    computed: {
    },
    data() {
        return {
            adminLinks: routes.adminLinks()
        }
    }
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

.selected > a {
    color: #f35b2c;
}

a:hover {
  text-decoration: none;
}
</style>