import APIConnection from '@/API/APIConnection';
export default {
    getAllUnassigned(){
        return APIConnection.get('admin/homepages/unassigned');
    },
    getAll() {
        return APIConnection.get('admin/homepages');
    },
    get(id) {
        return APIConnection.get(`admin/homepages/${id}`);
    },
    create(data) {
        return APIConnection.post(`admin/homepages`, data);
    },
    update(data) {
        return APIConnection.patch(`admin/homepages`, data);
    },
    getDefaults() {
        return APIConnection.get('admin/defaults/homepages');
    },
    delete(id) {
        return APIConnection.delete(`admin/homepages/${id}`);
    },
}