export const StoreState = {
    loading: 'loading',
    questions: 'questions',
    wordId: 'wordId',
    word: 'word',
    error: 'error'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.questions]: [],
    [StoreState.wordId]: null,
    [StoreState.error]: null
}