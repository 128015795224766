import Doctor from '@/adapters/Doctor';
import Home from '@/adapters/Home';
import AdminDoctorRepository from '@/API/AdminDoctorRepository';
import AdminHomeRepository from '@/API/AdminHomeRepository';
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getAll: 'getAll',
    get: 'get',
    getDefaults: 'getDefaults',
    create: 'create',
    edit: 'edit',
    getDoctors: 'getDoctors',
    delete: 'delete',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getAll] ({ commit }) {
        AdminHomeRepository.getAll().then((data) => {
            let items = data.items.map(it => {
                return new Home(it);
            })
            commit(StoreMutations.SET_ITEMS, items);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.get] ({ commit }, props) {
        AdminHomeRepository.get(props.id).then((data) => {
            let item = new Home(data.item);
            props.callback(item);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.getDefaults] ({ commit }, callback) {
        AdminHomeRepository.getDefaults().then((data) => {
            callback(data.defaults);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.create] ({ commit }, props) {
        AdminHomeRepository.create(props.data).then(() => {
            props.callback();
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.edit] ({ commit }, props) {
        AdminHomeRepository.update(props.data).then(() => {
            props.callback();
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.getDoctors] ({commit}) {
        AdminDoctorRepository.getAll().then(data => {
            let doctors = data.doctors.map(it => {
                return new Doctor(it);
            })
            commit(StoreMutations.SET_DOCTORS, doctors);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        });
    },
    [StoreActions.delete] ({ commit, dispatch }, id) {
        commit(StoreMutations.SET_LOADING, true);
        AdminHomeRepository.delete(id)
          .then(() => {
            dispatch(StoreActions.getAll);
          })
          .catch((err) => {
            commit(StoreMutations.SET_ERROR, err);
          })
          .finally(() => {
            commit(StoreMutations.SET_LOADING, false);
          });
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ITEMS, []);
        commit(StoreMutations.SET_DOCTORS, []);
        commit(StoreMutations.SET_ERROR, null);
    }
}