export const StoreState = {
    loading: 'loading',
    error: 'error',
    user: 'user',
    recentlyCompleted: 'recentlyCompleted',
    recentlyCompletedLoading: 'recentlyCompletedLoading',
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.user]: null,
    [StoreState.recentlyCompleted]: [],
    [StoreState.recentlyCompletedLoading]: false
}