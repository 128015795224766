export const StoreState = {
    loading: 'loading',
    error: 'error',
    home: 'home',
    passport: 'passport'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.home]: null,
    [StoreState.passport]: null
}