export const StoreState = {
    loading: 'loading',
    error: 'error',
    profiles: 'profiles',
    schools: 'schools',
    destination: 'destination'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.destination]: null,
    [StoreState.profiles]: [],
    [StoreState.schools]: [],
}