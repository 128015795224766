<template>
<div>
  <main-nav />
  <div class="container-full primary">
    <div class="row mt20">
      <div class="col-sm-12">
        <h2>My Profile</h2>
      </div>
    </div>
    <sync-loader
      :loading="loading"
      :color="`#f35b2c`"
      :size="'15px'"
      style="margin-top: 20px"
    ></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-12">
        <div class="alert" role="alert">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="row mt20" v-if="profile">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-md-offset-3 col-lg-offset-4">
        <div class="secondary ml30 curved mr20">
          <h4 class="mb20">{{ profile.firstName }} {{ profile.lastInitial }}</h4>
          <h5 v-if="profile.owner" class="mb20">Created By: {{profile.owner.firstName}} {{profile.owner.lastName}}</h5>

          <div class="primary curved p10 secondary-bg">
            <router-link :to="canboardRoute">
              <p>Canboard</p>
            </router-link>
          </div>


          <div class="primary curved p10 secondary-bg">
            <router-link :to="passportRoute" class="orange pointer">View CAN Passport</router-link>
          </div>

          <div class="primary curved p10 loc-bg">
            <p @click="logout" class="white pointer">Logout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/myProfile/state";
// import { StoreMutations as mutations } from "@/store/myProfile/mutations";
// import { StoreActions as actions } from "@/store/myProfile/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from '@/routes';

export default {
  name: "CompleteProfile",
  components: {
    MainNav,
    SyncLoader
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getProfile, (profile) => {
      this.profile = profile;
    });
  },
  data() {
    return {
      profile: null
    };
  },
  computed: {
    ...mapState(StorePaths.myProfile, [state.loading, state.error]),
    passportRoute() {
      return {
        name: routes.passport.name
      }
    },
    switchProfilesRoute() {
      return {
        name: routes.selectProfile.name
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    canboardRoute() {
      return {
        name: routes.selectCanboard.name
      }
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.myProfile}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.myProfile}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
  },
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

a {
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}
</style>