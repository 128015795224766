<template>
  <div>
    <sync-loader :loading="loading" :color="'#f35b2c'" :size="'15px'" style="padding-right:30px;margin-top:20px"></sync-loader>

    <div class="row mt20" v-if="error">
      <div class="col-sm-4 col-sm-offset-4">
        <div class="alert" :style="backgroundStyle" role="alert">
          {{error}}
        </div>
      </div>
    </div>

    <div v-if="speedrun">
      <h4 v-if="!finished">{{ speedrun.question }}</h4>
      <h3 style="margin-top: 10px; margin-bottom: 10px" v-if="running">
        {{ seconds.toFixed(2) }} seconds
      </h3>
      <button v-if="!running && !finished" class="btn btn-primary btn-start" @click="start()">
        Start
      </button>

      <p v-if="running">{{question.speedruns.length - index}} to go!</p>

      <div class="form-group" v-show="running">
        <input
          class="form-control"
          :placeholder="speedrun.placeholder"
          v-model="answer"
          ref="inputField"
        />
      </div>
    </div>

    <div v-if="finished">
      <h2>Good Job!</h2>
      <h3>{{ seconds.toFixed(2) }} s</h3>
      <h4 v-if="place && place.seconds < seconds">You previously placed {{placeName}} with a score of {{place.seconds.toFixed(2)}} seconds.</h4>
      <h4 v-else-if="place && place.seconds == seconds">New top score! You placed {{placeName}}.</h4>
      <h4 v-else-if="place">You placed {{placeName}} in the CAN-board!</h4>


      <button class="btn btn-primary" @click="reset() && start()">Try Again</button>
      <router-link :to="canboardRoute">
        <button class="btn btn-default ml20">View CAN-board</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Question from "@/adapters/Question";
import moment from "moment";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/canboard/state";
import { StoreActions as actions } from "@/store/canboard/actions";
import { StoreMutations as mutations } from "@/store/canboard/mutations";
import StorePaths from "@/store/StorePaths";
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import Utils from '@/utils/Utils';
import routes from '@/routes';

export default {
  name: "CanSpeedrun",
  components: {
    SyncLoader
  },
  props: {
    question: {
      type: Question,
    },
  },
  mounted() {
    this.reset();

    let wordId = this.$route.query.wordId;
    this.commit(mutations.SET_WORDID, wordId);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      index: 0,
      answer: "",
      running: false,
      timeBegan: null,
      diff: null,
      interval: null,
      seconds: 0,
      finished: false,
    };
  },
  computed: {
    ...mapState(StorePaths.canboard, [state.loading, state.error, state.place]),
    speedrun() {
      if (!this.question) {
        return null;
      }
      return this.question.speedruns[this.index];
    },
    placeName() {
      if(!this.place) {
        return "";
      }

      return Utils.numberSuffix(this.place.place);
    },
    canboardRoute() {
      return {
        name: routes.canboard.name,
        params: {
          wordId: this.$route.query.wordId
        }
      }
    }
  },
  watch: {
    answer(newVal) {
      if (newVal.toLowerCase() == this.speedrun.correctAnswer.toLowerCase()) {
        this.answer = "";
        if (this.index + 1 == this.question.speedruns.length) {
          this.confetti();
          this.stop();
        } else {
          this.index += 1;
        }
      }
    },
  },
  methods: {
    reset() {
        this.finished = false;
        this.index = 0;
        this.answer = "";
        this.running = false;
        this.timeBegan = null;
        this.diff = null;
        this.interval = null;
        this.seconds = 0;
        this.finished = false;
    },
    focus() {
      this.$nextTick(() => {
        let ref = this.$refs.inputField;
        if (ref) {
          ref.focus();
        }
      });
    },
    start() {
      if (this.running) {
        return;
      }

      this.running = true;
      this.focus();

      this.timeBegan = new moment();

      this.interval = setInterval(() => {
        let current = new moment();
        let diff = moment.duration(current.diff(this.timeBegan));
        this.seconds = diff.asSeconds();
      }, 100);
    },
    stop() {
      clearInterval(this.interval);
      this.finished = true;
      this.running = false;
      this.dispatch(actions.create, this.seconds);
      this.confetti();
    },
    confetti() {
      this.$emit('confetti');
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.canboard}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.canboard}/${key}`, val);
    },
  }
};
</script>

<style scoped>
input {
  font-size: large;
}

.btn {
  font-size: large;
  border-radius: 10px;
  margin-top: 20px;
}

.btn-start {
  font-size: x-large;
  border-radius: 10px;
  margin-top: 20px;
}
</style>