import CompletedWord from "@/adapters/CompletedWord";
import UserRepository from "@/API/UserRepository";
import WordRepository from "@/API/WordRepository";
import app from "@/main";
import routes from "@/routes";
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getRecentlyCompleted: 'getRecentlyCompleted',
    updateEmail: 'updateEmail',
    resendVerifyEmail: 'resendVerifyEmail',
    verifyEmail: 'verifyEmail',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getRecentlyCompleted]({ commit }) {
        commit(StoreMutations.SET_RECENTLY_COMPLETED_LOADING, true);
        WordRepository.recentlyCompleted().then((data) => {
            let items = data.items.map(it => {
                return new CompletedWord(it);
            })
            commit(StoreMutations.SET_RECENTLY_COMPLETED, items);
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_RECENTLY_COMPLETED_LOADING, false);
        });
    },
    [StoreActions.updateEmail] ({commit}, props) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.resendVerifyEmail({email: props.email}).then(() => {
            props.callback();
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.resendVerifyEmail] ({commit}, callback) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.resendVerifyEmail().then(() => {
            callback()
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.verifyEmail] ({commit}, token) {
        commit(StoreMutations.SET_LOADING, true);
        commit(StoreMutations.SET_ERROR, null);

        UserRepository.verifyEmail({code: token}).then(() => {
            app.config.globalProperties.$notify({
                type: 'success',
                title: 'Email Verified!'
              });

              app.config.globalProperties.$router.push({name: routes.myProfile.name});
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState]({ commit }) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_USER, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_RECENTLY_COMPLETED, []);
    }
}