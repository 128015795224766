import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_WORDS: 'SET_WORDS',
    SET_NEXT_UNLOCK: 'SET_NEXT_UNLOCK'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_WORDS](state, value) {
        state[StoreState.words] = value;
    },
    [StoreMutations.SET_NEXT_UNLOCK](state, value) {
        state[StoreState.nextUnlock] = value;
    }
}