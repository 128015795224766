<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Verify Email</h2>
        </div>
          <div class="col-sm-6 col-sm-offset-3">
              <div class="row mt20" v-if="error">
              <div class="col-sm-12">
                  <div class="alert" role="alert">
                  {{ error }}
                  </div>
              </div>
              </div>
          </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <sync-loader
            :loading="loading"
            :color="`#f35b2c`"
            :size="'15px'"
            style="margin-top: 20px"
          ></sync-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/myProfile/state";
import { StoreActions as actions } from "@/store/myProfile/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
export default {
  name: "VerifyEmail",
  components: {
    MainNav,
    SyncLoader
  },
  mounted() {
    let token = this.$route.query.code;
    this.dispatch(actions.verifyEmail, token);
  },
  data() {
    return {
        editingEmail: false,
        email: "",
        user: null
    }
  },
  computed: {
        ...mapState(StorePaths.myProfile, [state.loading, state.error]),
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.myProfile}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.myProfile}/${key}`, val);
    }
  }
};
</script>

<style scoped>
.btn {
    margin-top: 10px;
    border-radius: 15px;
    font-size: large;
}

p {
    font-size: medium;
}

.fa-bullhorn {
    font-size: 40pt;
    margin-bottom:20px;
    color: #f35b2c;
    background: #efefef;
    padding:15px;
    border-radius: 50px;
}

.change-email {
    border-radius: 20px;
    padding-bottom: 25px;
    padding-top: 10px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}
</style>