import Profile from "./Profile";
// import School from "./School";
import Classroom from './Classroom';

export default class User {
    constructor(adapter = {
        completedSetup: false,
        email: "",
        emailConfirmed: false,
        firstName: "",
        grade: null,
        id: 0,
        lastName: "",
        profiles: [],
        schoolName: null,
        type: null,
        accessCode: null,
        school: null,
        schoolDistrict: "",
        classroom: null,
        admin: false,
        completedWordEmail: false,
        completedAllWordsEmail: false
    }) {
        this.completedSetup = adapter.completedSetup;
        this.email = adapter.email;
        this.emailConfirmed = adapter.emailConfirmed;
        this.firstName = adapter.firstName;
        this.grade = adapter.grade;
        this.id = adapter.id;
        this.lastName = adapter.lastName;
        this.profiles = adapter.profiles.map(it => {
            return new Profile(it);
        });
        this.school = adapter.school;
        this.schoolDistrict = adapter.schoolDistrict;
        this.type = adapter.type;
        this.accessCode = adapter.accessCode;
        this.admin = adapter.admin;
        this.completedWordEmail = adapter.completedWordEmail;
        this.completedAllWordsEmail = adapter.completedAllWordsEmail;

        // if(adapter.school) {
        //     this.school = new School(adapter.school);
        // }

        if(adapter.classroom) {
            this.classroom = new Classroom(adapter.classroom);
        }
    }

    schoolDisplayName() {
        // if(this.school) {
        //     return this.school.name
        // }    

        return this.school;
    }
}