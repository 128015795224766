<template>
<div>
    <main-nav :canLinks="true" :color="'white'"/>
    <div class="container-full primary">

    <div class="anim-container" v-if="question && question.animation">
      <img :src="question.animation" class="anim"/>
    </div>
    <div v-else>

    <div class="header" :style="{background: word.color}" v-if="word">
      <img :src="word.image" />

      <div class="circle-container" v-tooltip="`Question ${index + 1} of ${questions.length}`">
        <progress-circle :diameter="30"
          v-if="questions.length > 0"
          :circle-color="'#ffffff'"
          :start-color="'#000000'"
          :stop-color="'#000000'"
          :circle-width="3"
          :animation-duration="1000"
          :inner-color="'transparent'"
          :percent-color="'inherit'"
          :total-steps="questions.length"
          :completed-steps="index == 0 ? index : index + 1"
        />
      </div>

      <!-- <div class="speaker-container mobile-hidden">
        <i class="fa fa-volume-up fa-lg pointer" v-tooltip="'Read the question'" :style="textColorStyle" />
      </div> -->
    </div>

    <sync-loader :loading="loading" :color="color" :size="'15px'" style="padding-right:30px;margin-top:20px"></sync-loader>


    <div class="row mt20" v-if="error">
      <div class="col-sm-4 col-sm-offset-4">
        <div class="alert" :style="backgroundStyle" role="alert">
          {{error}}
        </div>
      </div>
    </div>

    <div class="row" v-if="word">
      <div class="col-sm-12" v-if="question">

        <ConfettiExplosion :particleCount="question.complete ? 200 : 100" :force="0.3" v-if="confetti" :duration="question.complete ? 3500 : 2000" />

        <h2>{{ question.title }}</h2>
        <h4>{{ question.subTitle }}</h4>
        <p class="mt20">{{ question.question }}</p>

        <div class="row" v-if="question.video">
          <div class="col-sm-12">
            <iframe title="vimeo-player" :src="question.video" width="95%" height="360" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>

        <div class="row" v-if="question.book">
          <div class="col-sm-12">
            <iframe  :src="question.book" width="100%"
              height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay;
              clipboard-read; clipboard-write"></iframe>
          </div>
        </div>

        <div class="row" v-if="wrongAnswer">
          <div class="col-sm-4 col-sm-offset-4">
            <div class="alert" :style="wrongAnswerStyle" role="alert">
              That's not quite right. Let's double check your answer.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 col-sm-offset-4" v-if="question.type == 'text'">
            <div class="form-group" style="margin-left:10px; margin-right: 10px;">
              <input
                class="form-control"
                :placeholder="placeholder(question)"
                v-model="answer"
                ref="inputField"
                @keyup.enter="nextClick"
              />
            </div>
        </div>
        <can-form-option 
          :question="question" 
          :answer="answer" 
          :answers="answers" 
          :word="word"
          @optionClick="optionClick"
          :wrongAnswer="wrongAnswer"
        />
      </div>

      <div class="row" v-if="question.type == 'speedrun'">
        <div class="col-sm-4 col-sm-offset-4">
          <can-speedrun :question="question" @confetti="showConfetti" />
        </div>
      </div>

        <div class="row" v-if="!lastQuestion && question.type != 'options' && question.type != 'speedrun'">
          <div class="col-sm-4 col-sm-offset-4 mt20">
            <button
              class="btn btn-next"
               :style="backgroundStyle"
              @click="nextClick"
              :disabled="nextDisabled"
            >
              Next
            </button>
          </div>
        </div>

        <div class="row" v-if="lastQuestion">
          <div class="col-sm-4 col-sm-offset-4 mt20">
            <button
              class="btn btn-next"
               :style="backgroundStyle"
              @click="doneClick"
            >
              Collect Badge
            </button>
          </div>
        </div>
        
        <!-- <div class="row mobile-show mt20">
          <div class="col-sm-12">
            <button v-if="index > 0" class="btn btn-plain">Previous Questions</button>
            <br><br>
            <img :src="canLogo" height="50" />

          </div>
        </div> -->
      </div>
    </div>
      <router-link :to="homeRoute">
        <img class="logo" :src="canLogo" height="50" />
      </router-link>
      
      <button @click="showingPrevious = true" v-if="previousQuestions.length > 0" class="btn btn-plain previous-questions">Previous Questions</button>
    </div>

    <modal v-model="showBackModal" title="Modal Title" size="sm">
      <p>Are you sure you want to go back? Your progress will be lost.</p>
      <template v-slot:footer>
        <button class="btn btn-default" style="margin-right:10px;">Keep Going</button>
        <router-link :to="'/words'">
          <button class="btn btn-primary">Go Back</button>
        </router-link>
      </template>
    </modal>

    <can-form-questions :showing="showingPrevious" :questions="previousQuestions" @closed="showingPrevious = false" />

  </div>
</div>
</template>

<script>
import { ProgressCircle } from 'vue-progress-circle'
import StorePaths from '@/store/StorePaths';
import { mapState } from 'vuex';
import { StoreState as state } from '@/store/question/state';
import { StoreActions as actions } from '@/store/question/actions'; 
import { StoreMutations as mutations } from '@/store/question/mutations';
import Utils from '@/utils/Utils';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import CanFormOption from './CanFormOption.vue';
import ConfettiExplosion from "vue-confetti-explosion";
import CanLogo from '@/assets/images/canlogo.png';
import CanFormQuestions from '@/components/can/CanFormQuestions.vue';
import CanSpeedrun from './CanSpeedrun.vue';
import routes from '@/routes.js';
import MainNav from '@/components/MainNav.vue';

export default {
  name: "CanForm",
  components: {
    ProgressCircle,
    SyncLoader,
    CanFormOption,
    ConfettiExplosion,
    CanFormQuestions,
    CanSpeedrun,
    MainNav
  },
  mounted() {
    let wId = this.$route.query.wordId;
    if(!wId) {
      this.commit(mutations.SET_ERROR, "Word not found");
      return;
    }
    this.commit(mutations.SET_WORD_ID, wId);
    this.dispatch(actions.getWord);
    this.dispatch(actions.getQuestions);

    this.dispatch(actions.logEvent, {module: wId, name: 'Started_Word'});
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  computed: {
    ...mapState(StorePaths.question, [
      state.loading,
      state.questions,
      state.wordId,
      state.word,
      state.error
    ]),
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    textColor() {
      return Utils.getTextColor(this.color)
    },
    question() {
      return this.questions[this.index];
    },
    nextDisabled() {
      return (this.answer == '' && (this.question.type == 'text')) || this.lastQuestion
    },
    lastQuestion() {
      return this.question.complete == 1
    },
    percentComplete() {
      let percent = (this.index / this.questions.length) * 100;
      return `${percent.toFixed(0)}%`;
    },
    color() {
      if(this.word) {
        return this.word.color
      }
      return '#000';
    },
    backgroundStyle() {
      return {
        'background': this.color,
        'color': Utils.getTextColor(this.color)
      }
    },
    wrongAnswerStyle() {
      var color = "#D9594C";
      if(this.word.name == "Resilience") {
        color = "#000000";
      }

      return {
        background: color,
        color: 'white !important'
      }
    },
    textColorStyle() {
      return {color: Utils.getTextColor(this.color)}
    },
    previousQuestions() {
      if(this.index == 0) { return [] }
      return this.questions.slice(0, this.index).filter(it => {
        return it.title != null || it.subTitle != null || it.question != null;
      }).reverse();
    }
  },
  data() {
    return {
      index: 0,
      answer: "",
      answers: [],
      wrongAnswer: false,
      canLogo: CanLogo,
      confetti: false,
      wrongCount: 0,
      animationTimerRunning: false,
      showBackModal: false,
      showingPrevious: false
    };
  },
  methods: {
    sanitizeAnswer(answer) {
      var str = answer.toLowerCase()
      
      var lastChar = str.slice(-1);
      if (lastChar == '.' || lastChar == '!') {
          str = str.slice(0, -1);
      }

      return str
    },
    nextClick() {

      this.dispatch(actions.saveAnswer, {
        answer: this.answer, 
        answers: this.answers,
        index: this.index
      })
      
      if(this.question.correctAnswers.length > 0) {
        if(this.question.type == "text") {
          let lowercaseAnswers = this.question.correctAnswers.map(it => { return this.sanitizeAnswer(it) });
          if(!lowercaseAnswers.includes(this.sanitizeAnswer(this.answer))) {
            this.wrongAnswer = true
            this.wrongCount += 1
            return
          }
        }
        else if(this.question.type == "options") {
          if(!this.question.correctAnswers.includes(this.answer)) {
            this.wrongAnswer = true
            this.wrongCount += 1
            return
          }
        } else if(this.question.type == "multi-options") {
          let sortedAnswers = this.answers.sort();
          let sortedCorrect = this.question.correctAnswers;
          
          if(!Utils.compareArrays(sortedAnswers, sortedCorrect)) {
            this.wrongAnswer = true
            this.wrongCount += 1
            return
          }
        }
      }

      if(this.question.animation == null) {
        this.showConfetti();
      }
      setTimeout(() => {
        this.answer = "";
        this.answers = [];
        this.index += 1;
        this.wrongAnswer = false;
        this.wrongCount = 0;
        this.focusField();
      }, 500);
    },
    optionClick(option) {
      this.wrongAnswer = false;
      if(this.question.type == 'options') {
        this.answer = option;
        this.nextClick();
      } else if(this.question.type == 'multi-options') {
        if(this.answers.includes(option)) {
          this.answers = this.answers.filter(it => {
            return it != option;
          });
        } else {
          this.answers.push(option);
        }
      }
      this.$forceUpdate()
    },
    doneClick() {
      this.dispatch(actions.complete);
      this.dispatch(actions.logEvent, {module: this.wId, name: 'Finished_Word'});
      this.nextClick();
    },
    focusField() {
      this.$nextTick(() => {
        let ref = this.$refs.inputField;
        if(ref) {
          ref.focus();
        }
      });
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.question}/${key}`,val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.question}/${key}`,val);
    },
    showConfetti() {
      this.confetti = true;
      setTimeout(() => {
        this.confetti = false
      }, 2000);
    },
    placeholder(question) {
      if(this.wrongCount >= 3) {
        return question.hintPlaceholder;
      }
      return question.placeholder;
    }
  },
  watch: {
    wrongCount(newVal) {
      if(newVal == 3) {
        this.answer = "";
      }
    },
    question(newVal) {
        if(newVal.animationTime && !this.animationTimerRunning) {
          this.animationTimerRunning = true;
          setTimeout(() => {
          this.animationTimerRunning = false;

            if(this.questions.length - 1 == this.index) {
              this.$router.go(-1);
            } else {
              this.nextClick();
            }
          }, newVal.animationTime * 1000);
        }
    }
  }
};
</script>

<style scoped>
.container-full {
  height: calc(100vh - 90px);
  padding: 0px;
}

.header {
  height: 60px;
}

.header > img {
  height: 100%;
  width: auto;
}

@media (max-width:767px) {
  .header {
    /* height: 100px; */
  }
}

.fa-chevron-left {
  float: left;
  margin-top: 22px;
  margin-left: 15px;
}

.btn-next {
  font-size: large;
  border-radius: 15px;
  min-width: 150px;
  padding:10px;
  font-size: 1.3em;
}

.alert {
  font-weight: bold;
  font-size: large;
}

.circle-container {
    float: right;
    margin-right: 20px;
    margin-top: 14px;
}

.speaker-container {
    float: right;
    margin-right: 20px;
    margin-top: 18px;
}

h2 {
  font-size: 2.5em;
  padding-left:20px;
  padding-right:20px;
}

h4 {
  font-size: 1.9em;
  padding-left:20px;
  padding-right:20px;
}

p {
  font-size: 1.5em;
}

input {
  font-size: 1.5em;
  padding:10px;
  border-radius: 10px;
}

.alert {
  border-radius: 15px;
}

.logo {
  position: absolute;
  bottom: 85px;
  left: 30px;
}

.anim-container {
    position: static;
    top:0;
    left:0;
    height:100%;
}

.anim {
  height: 100%;
}

@media (max-width: 1000px) {
  .anim {
    width: 100%;
    height: auto;
  }
}

.previous-questions {
  position: absolute;
  bottom: 85px;
  right: 30px;
}

.btn-plain {
  background: transparent;
  border: none;
  color: #f35b2c;
}

.home {
  float: left;
  margin-top: 16px;
  margin-left: 20px;
  font-size: large;
  font-weight: bold;
  color: black;
}
</style>