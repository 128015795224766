export default class AdminQuestionOption {
    constructor(adapter = {
        id: 0,
        option: "",
        image: null,
        icon: null
    }) {
        this.id = adapter.id;
        this.option = adapter.option;
        this.image = adapter.image;
        this.icon = adapter.icon;
    }
}